/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { Container, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { CubeSpinner } from 'react-spinners-kit';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { format } from 'date-fns';
import { fetchLatencyAndTokens } from './apis/api'; // Adjust the path as necessary

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const LatencyAndTokens = ({ onChartClick, selectedData, startDate, endDate, showAllData }) => {
  const [data, setData] = useState({});
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!startDate || !endDate) return; // Ensure dates are set before fetching data
    const fetchData = async () => {
      try {
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');

        const payload = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        setLoading(true);
        const response = await fetchLatencyAndTokens(payload);
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching latency and tokens:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const adjustedData = [
    data.promptTokens || 0,
    data.completionTokens || 0,
    data.averageResponseTimeInSeconds || 0
  ].map((value) => value + 0.1);

  const chartData = {
    labels: ['Prompt Tokens', 'Completion Tokens', 'Average Response'],
    datasets: [
      {
        label: 'Latency & Tokens',
        data: adjustedData,
        backgroundColor: [
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 99, 132, 0.7)',
          'rgba(75, 192, 192, 0.7)'
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleChartClick = (elements) => {
    if (elements.length > 0) {
      setDetailsLoading(true);
      const { index } = elements[0];
      const label = chartData.labels[index];
      const value = chartData.datasets[0].data[index];
      const selectedSegment = {
        label,
        value,
        data
      };
      onChartClick('LatencyAndTokens', selectedSegment, false);
      setDetailsLoading(false);
    }
  };

  const options = {
    onClick: (event, elements) => handleChartClick(elements),
    cutout: '40%',
    rotation: 0,
    circumference: 360,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: 'black',
        font: {
          weight: 'bold',
          size: 14,
        },
        formatter: (value) => value.toFixed(2)
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            return `${label}: ${value}`;
          }
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          boxWidth: 16,
          padding: 20,
          fullSize: true,
        },
      }
    },
  };

  const limitedChartData = {
    labels: chartData.labels,
    datasets: [
      {
        label: 'Latency & Tokens',
        data: chartData.datasets[0].data,
        backgroundColor: chartData.datasets[0].backgroundColor,
        borderColor: chartData.datasets[0].borderColor,
        borderWidth: chartData.datasets[0].borderWidth,
      },
    ],
  };

  let displayedChartData;
  if (showAllData) {
    displayedChartData = chartData;
  } else if (selectedData) {
    displayedChartData = {
      labels: [selectedData.label],
      datasets: [
        {
          label: 'Latency & Tokens',
          data: [selectedData.value],
          backgroundColor: [chartData.datasets[0].backgroundColor[chartData.labels.indexOf(selectedData.label)]],
          borderColor: [chartData.datasets[0].borderColor[chartData.labels.indexOf(selectedData.label)]],
          borderWidth: chartData.datasets[0].borderWidth,
        },
      ],
    };
  } else {
    displayedChartData = limitedChartData;
  }

  return (
    <Container sx={{ background: '#fff !important', minHeight: 'auto !important' }}>
      <Typography variant="h6" gutterBottom>
        Latency & Tokens
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={selectedData ? 6 : 12}>
          <div style={{ position: 'relative', height: '300px', width: '300px', margin: 'auto' }}>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CubeSpinner
                  size={40}
                  frontColor="#ff6384"
                  backColor="#36a2eb"
                  leftColor="#ffcd56"
                  rightColor="#4bc0c0"
                  topColor="#9966ff"
                  bottomColor="#ff9f40"
                />
              </div>
            ) : (
              <Doughnut
                data={displayedChartData}
                options={options}
              />
            )}
          </div>
        </Grid>
        {selectedData && (
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Details for
              {selectedData.label}
            </Typography>
            {detailsLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                <CubeSpinner
                  size={40}
                  frontColor="#ff6384"
                  backColor="#36a2eb"
                  leftColor="#ffcd56"
                  rightColor="#4bc0c0"
                  topColor="#9966ff"
                  bottomColor="#ff9f40"
                />
              </div>
            ) : (
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Metric</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{selectedData.label}</TableCell>
                      <TableCell>{selectedData.value}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

LatencyAndTokens.propTypes = {
  onChartClick: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  showAllData: PropTypes.bool.isRequired,
};

export default LatencyAndTokens;
