import { ComponentProps, FC, ReactNode, useRef, useState } from 'react';
import useHover from 'src/hooks/useHover';
import { getIsFirefox } from 'src/utils/webPlatformUtils';
import * as S from './CopyIconButton.styled';
import { useTheme } from '@material-ui/core/styles';
import Clipboard from 'src/icons/Clipboard';
import Check from 'src/icons/Check';
import PropTypes from 'prop-types';
import ContentPasteOutlinedIcon from '@material-ui/icons/ContentPasteOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

/* eslint-disable */
const CopyIconButton: FC<{
  iconColor?: string;
  value: string;
  iconSize?: number;
  onPress?: () => void;
}> = ({ iconSize, iconColor, value, onPress, ...rest }) => {
  const theme = useTheme();
  const [hoverRef, isHovered] = useHover();
  const [showTick, setShowTick] = useState(false);
  const timer = useRef(null);

  return (
    <S.CopyButton
      style={{
        marginTop: '2px'
      }}
      ref={hoverRef}
      isHovered={isHovered}
      onClick={() => {
        if (timer.current) {
          return;
        }

        // Page - e.g. Dashboard
        // elements e.g. grid, Chart, Values Widget
        // Each container -> Components -> building block ->Div Chart, Input, Dropdowns

        navigator.clipboard.writeText(value);
        setShowTick(true);

        timer.current = setTimeout(() => {
          timer.current = null;
          setShowTick(false);
        }, 3000);

        onPress?.();
      }}
    >
      {!showTick && <ContentPasteOutlinedIcon
        viewBox='0 0 24 24' sx={{ width: '20px', height: '20px', fill: '#2B5D8C', color: '#2B5D8C', '&:hover': { fill: '#2B5D8C', color: '#2B5D8C' } }}
        color={iconColor || theme.palette['appColors'].bodyText} />}
      {showTick && <CheckOutlinedIcon viewBox='0 0 24 24' style={{ width: '20px', height: '20px' }} />}
    </S.CopyButton>
  );
};

CopyIconButton.propTypes = {
  iconSize: PropTypes.number,
  value: PropTypes.string.isRequired,
  onPress: PropTypes.func,
};

export default CopyIconButton;
