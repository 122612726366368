/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { Container, Typography, Table, TableBody, TableCell, Button, TableContainer, TableHead, TableRow, Paper, TextField, TablePagination, Grid } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CubeSpinner } from 'react-spinners-kit';
import { fetchBotResponses } from './apis/api';
import '../dashboard/chat/styles/chat.css';
import { format } from 'date-fns';

// Register Chart.js components and the datalabels plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  ChartDataLabels
);

const BotResponses = ({ onChartClick, selectedData, startDate, endDate, showAllData }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const chartContainerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [detailsLoading, setDetailsLoading] = useState(false);

  useEffect(() => {
    if (!startDate || !endDate) return; // Ensure dates are set before fetching data
    const fetchData = async () => {
      try {
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');

        const payload = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        console.log(payload);
        const response = await fetchBotResponses(payload);
        const responseData = response.data.data;

        setData(responseData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching bot response:', error);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const truncateLabel = (label) => (label.length > 30 ? `${label.substring(0, 17)}...` : label);

  const limitedChartData = {
    labels: data.slice(0, 10).map((item) => truncateLabel(item.conversationResponse)),
    datasets: [
      {
        label: 'Bot Responses',
        data: data.slice(0, 10).map((item) => item.count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartData = {
    labels: data.map((item) => truncateLabel(item.conversationResponse)),
    datasets: [
      {
        label: 'Bot Responses',
        data: data.map((item) => item.count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const maxDataValue = Math.max(...data.map((item) => item.count));
  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        max: maxDataValue + 0.5,
      },
      y: {
        grid: {
          display: false,
        },
        autoSkip: false,
        textAlign: 'left',
        position: 'left',
        maxWidth: 30,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
        color: 'black',
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => data[tooltipItem[0].dataIndex].conversationResponse,
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        setDetailsLoading(true);
        const { index } = elements[0];
        const selectedConversation = data[index];
        onChartClick('BotResponses', selectedConversation, false);
        setDetailsLoading(false);
      }
    },
  };

  const handleShowAllData = () => {
    onChartClick('BotResponses', data, true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  let displayedChartData;
  if (showAllData) {
    displayedChartData = chartData;
  } else if (selectedData) {
    displayedChartData = {
      labels: [selectedData.conversationResponse],
      datasets: [
        {
          label: 'Bot Responses',
          data: [selectedData.count],
          backgroundColor: [
            'rgba(255, 99, 132, 0.7)',
            'rgba(54, 162, 235, 0.7)',
            'rgba(255, 206, 86, 0.7)',
            'rgba(75, 192, 192, 0.7)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  } else {
    displayedChartData = limitedChartData;
  }

  const paginatedData = showAllData ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data;

  return (
    <Container sx={{ background: '#fff !important', minHeight: 'auto !important' }}>
      <Typography
        variant="h6"
        gutterBottom
      >
        Bot Responses
      </Typography>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CubeSpinner
            size={40}
            frontColor="#ff6384"
            backColor="#36a2eb"
            leftColor="#ffcd56"
            rightColor="#4bc0c0"
            topColor="#9966ff"
            bottomColor="#ff9f40"
          />
        </div>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={selectedData || showAllData ? 6 : 12}>
            {showAllData ? (
              <div style={{ height: '300px', overflowY: 'scroll' }} ref={chartContainerRef}>
                <div style={{ height: `${data.length * 40}px` }}>
                  <Bar
                    data={displayedChartData}
                    options={options}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            ) : (
              <div style={{ height: '320px', overflowY: 'scroll' }} ref={chartContainerRef}>
                <Bar
                  data={displayedChartData}
                  options={options}
                  plugins={[ChartDataLabels]}
                />
              </div>
            )}
            {showAllData ? '' : (
              <Button
                variant="contained"
                className="chartsButtons"
                onClick={handleShowAllData}
                sx={{
                  margin: '0',
                  padding: '2px 6px',
                  fontSize: '0.75rem',
                  minWidth: '70px',
                  height: '28px',
                }}
              >
                {showAllData ? 'Show Less' : 'Show All'}
              </Button>
            )}
          </Grid>
          {(selectedData || showAllData) && (
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                {showAllData ? 'Complete Data' : `Query Details: ${selectedData ? selectedData.conversationResponse : ''}`}
              </Typography>
              {detailsLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                  <CubeSpinner
                    size={40}
                    frontColor="#ff6384"
                    backColor="#36a2eb"
                    leftColor="#ffcd56"
                    rightColor="#4bc0c0"
                    topColor="#9966ff"
                    bottomColor="#ff9f40"
                  />
                </div>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Answer</TableCell>
                          <TableCell>Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {showAllData && paginatedData.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.conversationResponse}</TableCell>
                            <TableCell>{item.count}</TableCell>
                          </TableRow>
                        ))}
                        {!showAllData && selectedData && (
                          <TableRow>
                            <TableCell>{selectedData.conversationResponse}</TableCell>
                            <TableCell>{selectedData.count}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={showAllData ? data.length : data.slice(0, 10).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  );
};

BotResponses.propTypes = {
  onChartClick: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
  showAllData: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

export default BotResponses;
