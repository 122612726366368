/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { getUserByEmail } from 'src/admin/api/user';
import { useNavigate } from 'react-router-dom';
import {
  setUnauthenticatedUserSession,
  setUserAuthenticationStatus,
} from 'src/slices/auth';
import { useDispatch, useSelector } from 'src/store';
import jwt_decode from 'jwt-decode';
import { setUserProfile } from 'src/slices/user';
import { isEmpty } from 'lodash';
import { extractUserSessionFromPayload } from 'src/utils';
import { appBguseStyles } from 'src/App';
import { getLoggedInUser } from 'src/api/auth';
import { ERROR_CODES } from 'src/constants';

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  content: {
    textAlign: 'center',
  },
}));

const SocialVerification: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = appBguseStyles();
  const contentclasses = useStyles();
  const { userSession } = useSelector((state) => state.auth);
  const [showLoader, setShowLoader] = useState(true);
  const { idToken = '', accessToken = ''} = userSession || {};
  useEffect(() => {
    getLoggedInUser().then(res =>{
      dispatch(
        setUnauthenticatedUserSession(
         extractUserSessionFromPayload(res)
       )
      );
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(idToken)) {
      const idJWT: any = jwt_decode(idToken);
      const accessJWT: any = jwt_decode(accessToken);
      getUserByEmail(idJWT.email?.trim())
        .then(async (res) => {
          await dispatch(setUserProfile(res.data));
          await dispatch(setUserAuthenticationStatus(true));
        })
        .catch((err) => {
          if(err?.response?.data?.error_code === ERROR_CODES.USER_NOT_FOUND){
            navigate('/auth/social-register', {
              state: {
                comingFromLogin: true,
                userData: {
                  first_name: idJWT.given_name,
                  last_name: idJWT.family_name,
                  username: accessJWT.username,
                  email: idJWT.email?.trim()
                },
              },
            });
          }
          else{
            navigate('/');
          }
        })
        .finally(() => {
        });
    }
  }, [idToken]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}
      className={classes.root}
    >
      {showLoader && (
        <Box className={contentclasses.overlay}>
          <Typography sx={{ mb: 2 }} color="white" gutterBottom variant="h6">
            Please wait while we are verifying information...
          </Typography>
          <CircularProgress style={{ color: 'white' }} />
        </Box>
      )}
    </Box>
  );
};

export default SocialVerification;
