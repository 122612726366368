export const trimmedString = (unTrimmedString) => String(unTrimmedString || '').trim();

export const extractUserSessionFromPayload = ({ token, refresh_token }) => ({ idToken: token, accessToken: token, refreshToken: refresh_token });

// export const extractUserSessionFromPayload = ({ token, refresh_token }) => {
//   // const userSession = payload.signInUserSession;
//   // const idToken = userSession.idToken.jwtToken;
//   // const accessToken = userSession.accessToken.jwtToken;
//   // const refreshToken = userSession.refreshToken.token;
//   return { idToken: token, accessToken: token, refreshToken: refresh_token };
// };

export const formatDateToString = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const extractFileName = (fileName: string) => {
  if (!fileName) {
    return fileName;
  }

  return fileName.replace(/^.*(\\|\/|:)/, '');
};
