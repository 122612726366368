/*eslint-disable*/
import { FC } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Divider,
  TextField,
  Dialog,
  Typography,
  Box,
  Container,
  CardContent,
  Card,
  FormHelperText,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { createURL, updateURL } from 'src/api/url';
import useMounted from 'src/hooks/useMounted';
import { IURL } from 'src/types/url';
import { isEmpty } from 'lodash';
import { useSelector } from 'src/store';

interface ConfigurationsAddProps {
  open: boolean;
  onCancel?: () => void;
  onSuccess?: (params) => void;
  data?: IURL;
}

const ConfigurationsAdd: FC<ConfigurationsAddProps> = ({
  open,
  onCancel = () => {},
  onSuccess = () => {},
  data = { name: '', url: '' } as IURL,
}) => {
  const { userProfile = {} } = useSelector((state) => state.user);
  const { id } = userProfile;
  const isEditMode = !isEmpty(data);

  const mounted = useMounted();
  return (
    <Dialog fullWidth maxWidth="sm" onClose={onCancel} open={open}>
      <DialogContent
        sx={{
          backgroundColor: 'background.default',
          px: 2,
          py: 1,
          mx: 0,
        }}
      >
        <DialogTitle>
          <Typography color="textPrimary" variant="h4">
            {isEditMode ? 'Edit Configuration' : 'Create Configuration'}
          </Typography>
        </DialogTitle>
        <Box
          sx={{
            backgroundColor: 'background.default',
            px: 0,
            py: 1,
            mx: 0,
          }}
        >
          <Container maxWidth="sm">
            <Card>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 2,
                }}
              >
                <Formik
                  initialValues={{
                    name: '' || data?.name,
                    url: '' || data?.url,
                    submit: null,
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .min(1, 'Name must be at least 1 character long')
                      .max(100, 'Name cannot exceed 100 characters')
                      .required('Name is required'),
                    url: Yup.string()
                      .matches(
                        /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\\/]))?/,
                        'Invalid URL'
                      )
                      .required('URL is required'),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ): Promise<void> => {
                    try {
                      const endpoint = isEditMode ? updateURL : createURL;
                      await endpoint({
                        name: values.name,
                        url: values.url,
                        id: data?.id,
                      });
                      onSuccess(true);
                      if (isEditMode) {
                        toast.success('Configuration updated!');
                      } else {
                        toast.success('Configuration added!');
                      }
                    } catch (err) {
                      console.error(err);
                      if (mounted.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                      }
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }): JSX.Element => (
                    <form noValidate onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label="Name"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.name}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.url && errors.url)}
                        fullWidth
                        helperText={touched.url && errors.url}
                        multiline
                        rows={3}
                        label="URL"
                        margin="normal"
                        name="url"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="url"
                        value={values.url}
                        variant="outlined"
                      />
                      {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}
                      <Divider sx={{ my: 3 }} />
                      <Box sx={{ mt: 2 }}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Save
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          </Container>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfigurationsAdd.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }) as PropTypes.Requireable<IURL>,
};

export default ConfigurationsAdd;
