import React from 'react';
import { createSvgIcon } from '@mui/material';

const SendButton = createSvgIcon(
  <svg viewBox="0 0 22 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.498779 17.75V0.25L21.7488 9L0.498779 17.75ZM2.73562 14.4688L15.9889 9L2.73562 3.53125V7.35938L9.44615 9L2.73562 10.6406V14.4688Z" />
  </svg>,
  'SampleQuestionIcon'
);

export default SendButton;
