/*eslint-disable*/
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, CircularProgress, useMediaQuery, Theme, IconButton, experimentalStyled } from '@material-ui/core';
import type { Message } from 'src/types/chat';
import Scrollbar from '../../Scrollbar';
import ChatMessage from './ChatMessage';
import { useSelector, useDispatch } from 'src/store';
import { isEqual } from 'lodash';
import { CHAT_ANSWER_LOADER } from 'src/constants';
import SelectModel from '../SelectModel';
import ResizeDetector from 'react-resize-detector';
import CustomScrollbar from 'src/components/CustomScrollbar/CustomScrollbar';
import useMounted from 'src/hooks/useMounted';
import { toggleIsDocumentViewerOpen, setThreadMountedStatus } from 'src/slices/chat';
import { ArrowDownward } from '@material-ui/icons';
import { Px2Rem } from 'src/theme/pxtorem';
import colors from 'src/theme/colors';
import ChatMessageLoader from './ChatMessageLoader';
import ChatDocumentViewer from './ChatDocumentViewer';


interface ChatMessagesProps {
  messages: Message[];
  threadKey?: any;
  thread?: any;
  enableAutoScrollWhileTyping: boolean;
  showBottomNavigation: boolean;
  parentRef: any;
}

const ChatMessages: FC<ChatMessagesProps> = (props) => {
  const { messages, threadKey, thread, parentRef, enableAutoScrollWhileTyping, showBottomNavigation, ...other } = props;
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(760));
  const mounted = useMounted();
  const dispatch = useDispatch();
  const {
    activeThreadId,
    isDocumentViewerOpen,
  } = useSelector((state) => state.chat);

  const rootRef = useRef<any>(null);
  // To check with team
  const { userProfile = {} } = useSelector((state) => state.user);
  // const { messageLoading } = useSelector((state) => state.chat);
  const { msgLoading, selectedModel, isMounted } = thread;
  // To check with team
  // const { id } = userProfile;
  // const [isDocumentViewerOpen, setIsSplit] = useState<boolean>(false);

  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [sourceDocs, setSourceDocs] = useState<string>('');

  const openPdfSection = (url: string, sourceDocs: any) => {
    // setIsSplit(false);
    setDocumentUrl(url);
    setSourceDocs(sourceDocs);
    dispatch(toggleIsDocumentViewerOpen(true));
  };

  const scrollToBottom = () => {
    if (rootRef?.current) {
      rootRef.current.scrollTop =
        rootRef.current.scrollHeight;
      rootRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const getGroupedSourceDocuments = (documents = []) => {
    const groupedDocs = {};
    documents.forEach(([doc, euclideanDistance]) => {
      if (!groupedDocs[doc.metadata.url]) {
        groupedDocs[doc.metadata.url] = [];
      }

      groupedDocs[doc.metadata.url].push({ ...doc, euclideanDistance });
    });

    return groupedDocs;
  }

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [activeThreadId, mounted]);

  return (
    <ResizeDetector
      handleWidth
      handleHeight
      onResize={(width?: number, height?: number) => {
        if (rootRef?.current && enableAutoScrollWhileTyping) {
          scrollToBottom();
        }
      }}
    >
      {showBottomNavigation && (
        <IconButton
          onClick={scrollToBottom}
          sx={{
            position: 'absolute',
            top: '118px',
            right: '20px',
            float: 'right',
            zIndex: 1,
            /* top: {
              xs: `${(parentRef?.current?.clientHeight - 10)}px`,
              sm: `${(parentRef?.current?.clientHeight - 10)}px`,
              md: `${(parentRef?.current?.clientHeight - 10)}px`,
              lg: `${(parentRef?.current?.clientHeight - 10)}px`,
              xl: `${(parentRef?.current?.clientHeight - 150)}px`,
            },
            right: {
              xs: '10vh',
              sm: '24vh',
              md: '28vh',
              lg: '30vh',
              xl: '30vh'
            }, */
            borderRadius: '50%',
            backgroundColor: `${colors.mainColor} !important`,
            width: {
              xs: Px2Rem(15),
              sm: Px2Rem(15),
              md: Px2Rem(20),
              lg: Px2Rem(28),
              xl: Px2Rem(40),
            },
            height: {
              xs: Px2Rem(15),
              sm: Px2Rem(15),
              md: Px2Rem(20),
              lg: Px2Rem(28),
              xl: Px2Rem(40),
            }
          }}
        >
          <ArrowDownward
            sx={{
              fontSize: {
                xs: Px2Rem(14),
                sm: Px2Rem(14),
                md: Px2Rem(16),
                lg: Px2Rem(20),
                xl: Px2Rem(24),
              }
            }}
            style={{
              color: colors.white
            }}
          />
        </IconButton>
      )}
      <CustomScrollbar ignoreHeight={true} ref={rootRef} {...other}>

        <Box display="flex">
          <Box width={isDocumentViewerOpen ? '67%' : '100%'}>
            {messages?.map((message, index) => {
              const { question, answer, source_documents, reaction } = message;
              let senderAvatar;
              let groupedSourceDocuments = getGroupedSourceDocuments(source_documents);
              if (message.question) {
                //Temp Fix
                //  senderAvatar = userProfile.image;
                senderAvatar =
                  '/static/mock-images/avatars/avatar-jane_rotanson.png';
              } else {
                senderAvatar =
                  '/static/mock-images/avatars/avatar-jane_rotanson.png';
              }
              // Render question and answer messages
              return (
                <>
                  <ChatMessage
                    body={question}
                    loader={null}
                    showIcons={true}
                    key={`questionMessageId${index}`}
                    thread={thread}
                    onPdfDocClick={openPdfSection}
                    threadKey={threadKey}
                    senderAvatar={senderAvatar}
                    senderType={'user'}
                    msgLoading={msgLoading}
                  />

                  {!isEqual(answer, CHAT_ANSWER_LOADER) ?
                    <ChatMessage
                      body={answer}
                      source_documents={groupedSourceDocuments}
                      reaction={reaction}
                      loader={null}
                      msgLoading={msgLoading}
                      showIcons={message.showIcons}
                      key={`answerMessageId${index}`}
                      msgId={message.id}
                      externalConvId={message.external_conv_id}
                      thread={thread}
                      onPdfDocClick={openPdfSection}
                      threadKey={threadKey}
                      senderAvatar={'/static/mock-images/avatars/avatar-jane_rotanson.png'}
                      senderType={'dewa'}
                    />
                    :
                    msgLoading && (<ChatMessage
                      body={'showLoader'}
                      msgLoading={msgLoading}
                      showIcons={false}
                      onPdfDocClick={openPdfSection}
                      loader={<ChatMessageLoader />}
                      key={'loaderMessageId'}
                      thread={{ selectedModel }}
                      threadKey={threadKey}
                      senderAvatar={
                        '/static/mock-images/avatars/avatar-jane_rotanson.png'
                      }
                      senderType={'dewa'}
                    />)
                  }
                </>
              );
            })}
          </Box>
          {isDocumentViewerOpen && (<Divider orientation="vertical" flexItem />)}
          {isDocumentViewerOpen && (

            <Box>
              <ChatDocumentViewer
                url={documentUrl}
                setIsSplit={(val: boolean) => {
                  dispatch(toggleIsDocumentViewerOpen(val));
                }}
                sourceDocuments={sourceDocs}>
              </ChatDocumentViewer>
            </Box>
          )}
        </Box>
      </CustomScrollbar>
    </ResizeDetector>
  );
};

ChatMessages.propTypes = {
  // @ts-ignore
  messages: PropTypes.array,
  threadKey: PropTypes.any,
  thread: PropTypes.any,
};

export default ChatMessages;
