import { APP_ENVIRONMENT } from 'src/config';

const userSessionManager = {
  session: null,
  profile: null,
  configurations: null,
};

// eslint-disable-next-line
const environmentBasedUniqueKey = (key: string): string => `${APP_ENVIRONMENT}_${key}`;

const USER_SESSION_KEY = environmentBasedUniqueKey('USER_SESSION_KEY');
const USER_PROFILE_KEY = environmentBasedUniqueKey('USER_PROFILE_KEY');
const DEPARTMENTS_LIST_KEY = environmentBasedUniqueKey('DEPARTMENTS_LIST');

export const saveUserSession = (userSession: any): void => {
  localStorage.setItem(USER_SESSION_KEY, JSON.stringify(userSession));
};

export const getSavedUserSession = (): any => {
  const sessionData = localStorage.getItem(USER_SESSION_KEY);
  return sessionData ? JSON.parse(sessionData) : null;
};

export const saveUserProfile = (userProfile: any): void => {
  localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(userProfile));
};

export const getSavedUserProfile = (): any => {
  const profileData = localStorage.getItem(USER_PROFILE_KEY);
  return profileData ? JSON.parse(profileData) : null;
};

export const saveDepartmentsList = (departments: any[]): any => {
  if (!departments) {
    localStorage.removeItem(DEPARTMENTS_LIST_KEY);
    return;
  }

  localStorage.setItem(DEPARTMENTS_LIST_KEY, JSON.stringify(departments));
};

export const getDepartmentsList = (): any => {
  const departments = localStorage.getItem(DEPARTMENTS_LIST_KEY);

  return departments ? JSON.parse(departments) : null;
};
