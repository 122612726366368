import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { saveUserSession } from 'src/store/localStorage';

interface AuthState {
  userSession: {
    idToken: string,
    accessToken: string,
    refreshToken: string,
  };
  isAuthenticated: boolean;
  cognitoUserForIntermediateAction: any;
  isSocialLogin: boolean;
}

const initialState: AuthState = {
  userSession: null,
  isAuthenticated: false,
  cognitoUserForIntermediateAction: null,
  isSocialLogin: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserSession(
      state: AuthState,
      action: PayloadAction<any>
    ): void {
      const { userSession = {}, isAuthenticated = false } = action?.payload || {};
      state.userSession = userSession;
      state.isAuthenticated = isAuthenticated;
    },
    setUnauthenticatedUserSession(
      state: AuthState,
      action: PayloadAction<any>
    ): void {
      state.userSession = action.payload;
      state.isAuthenticated = false;
    },
    setAuthenticatedUserStatus(state: AuthState, action: PayloadAction<any>): void {
      state.isAuthenticated = action.payload;
    },
    setIsSocialLoginStatus(state: AuthState, action: PayloadAction<any>): void {
      state.isSocialLogin = action.payload;
    },
    clearUserSession(state: AuthState): void {
      state.userSession = null;
      state.isAuthenticated = false;
    }
  },
});

export const { reducer } = slice;

export const setUnauthenticatedUserSession = (data: any): AppThunk => async (dispatch, getState): Promise<void> => {
  await dispatch(slice.actions.setUnauthenticatedUserSession(data));
  const currentState = getState();
  saveUserSession(currentState.auth);
};

export const setIsSocialLoginStatus = (status): AppThunk => async (dispatch, getState) : Promise<void> => {
  dispatch(slice.actions.setIsSocialLoginStatus(status));
  const currentState = getState();
  saveUserSession(currentState.auth);
};
export const setUserAuthenticationStatus = (status): AppThunk => async (dispatch, getState) : Promise<void> => {
  dispatch(slice.actions.setAuthenticatedUserStatus(status));
  const currentState = getState();
  saveUserSession(currentState.auth);
};

export const setUserSession = (data): AppThunk => async (dispatch) : Promise<void> => {
  dispatch(slice.actions.setUserSession(data));
  saveUserSession(data);
};

export const clearUserSession = (): AppThunk => async (dispatch) : Promise<void> => {
  dispatch(slice.actions.clearUserSession());
  saveUserSession({});
};

export default slice;
