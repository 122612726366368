import { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import stylisRTLPlugin from 'stylis-plugin-rtl';

interface RTLProps {
  name?: string;
  children: ReactNode;
  direction: 'ltr' | 'rtl';
}

const styleCache = (direction) => createCache({
  key: direction,
  prepend: true,
  // We have to temporary ignore this due to incorrect definitions
  // in the stylis-plugin-rtl module
  // @see https://github.com/styled-components/stylis-plugin-rtl/issues/23
  // @ts-ignore
  stylisPlugins: [stylisRTLPlugin]
});

const RTL: FC<RTLProps> = (props) => {
  const { children, direction, name } = props;
  const [elementDirection, setElementDirection] = useState(direction);

  useEffect(() => {
    // document.dir = direction;
    setElementDirection(direction);
  }, [direction]);

  if (direction === 'rtl' || name === 'DialogView') {
    return (
      <CacheProvider value={styleCache(elementDirection)}>
        {children}
      </CacheProvider>
    );
  }

  return <div style={{ direction: elementDirection }}>{children}</div>;
};

RTL.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['ltr', 'rtl'])
};

export default RTL;
