import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { getUserByEmail, updateUser } from 'src/admin/api/user';
import { saveUserProfile } from 'src/store/localStorage';
import { clearUserSession } from './auth';
import i18n from 'src/i18n';
import { resetChat } from './chat';
import { clearDepartments } from './metadata';

interface UserState {
  userProfile: any, // BaseUser
}

const initialState: UserState = {
  userProfile: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile(state: UserState, action: PayloadAction<any>): void {
      state.userProfile = action.payload;
    },
    clearUserProfile(state: UserState): void {
      state.userProfile = null;
    },
    updateUserProfileByKey(state: UserState, action: PayloadAction<any>): void {
      const { key, value } = action.payload;
      state.userProfile = {
        ...state.userProfile,
        [key]: value,
      };
    },
    updateUserProfile(state: UserState, action: PayloadAction<any>): void {
      const data = action.payload;
      state.userProfile = {
        ...state.userProfile,
        ...data,
      };
    },
  },
});

export const { reducer } = slice;

export const setUserProfile = (data): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setUserProfile(data));
  saveUserProfile(data);
};

export const clearUserProfile = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.clearUserProfile());
  saveUserProfile({});
};

export const getUserProfile = (email: string): AppThunk => async (dispatch): Promise<void> => {
  try {
    const response = await getUserByEmail(email);
    const loggedInUser = response?.data;
    dispatch(setUserProfile(loggedInUser));
    saveUserProfile(loggedInUser);
  } catch (error) {
    throw new Error(i18n.t('userDetailsError'));
  }
};

export const updateUserProfileByKey = (key, value: any): AppThunk => async (dispatch, getState): Promise<void> => {
  dispatch(slice.actions.updateUserProfileByKey({ key, value }));
  const currentState = getState();
  saveUserProfile(currentState.user.userProfile);
};

export const updateProfile = (data: any): AppThunk => async (dispatch, getState): Promise<void> => {
  try {
    await updateUser(data);
    dispatch(slice.actions.updateUserProfile(data));
    const currentState = getState();
    saveUserProfile(currentState.user.userProfile);
  } catch (error) {
    throw new Error(i18n.t('userUpdateError'));
  }
};

export const clearStore = (): AppThunk => async (dispatch): Promise<void> => {
  await dispatch(clearUserSession());
  await dispatch(clearUserProfile());
  await dispatch(resetChat());
  await dispatch(clearDepartments());
};

export default slice;
