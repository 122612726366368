export const amplifyConfig = {
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_auth_domain: process.env.REACT_APP_AUTH_DOMAIN,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

// eslint-disable-next-line
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const SOCIAL_APP_URL = process.env.REACT_APP_SOCIAL_ALL_URL;
export const TAMM_APP_URL = process.env.REACT_APP_TAMM_ALL_URL;
export const APP_MAIN_URL = process.env.REACT_APP_MAIN_URL;
export const POWERBI_DASHBOARD_URL = process.env.REACT_APP_POWERBI_DASHBOARD_URL;
export const SSO_ENABLED = process.env.REACT_APP_SSO_ENABLED;
export const DEWA_PORTAL_URL = process.env.REACT_APP_DEWA_PORTAL_URL;
