/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { Container, Typography, Table, Box, TableBody, ToggleButton, ToggleButtonGroup, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, TextField, TablePagination } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CubeSpinner } from 'react-spinners-kit';
import { fetchUserInteractions } from './apis/api';
import '../dashboard/chat/styles/chat.css';
import { format } from 'date-fns';

// Register Chart.js components and the datalabels plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  ChartDataLabels
);

const UserInteractions = ({ onChartClick, selectedData, startDate, endDate, showAllData }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const chartContainerRef = useRef(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [showToggel, setShowToggel] = useState(true);
  const [alignment, setAlignment] = useState('weekly');

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  useEffect(() => {
    if (!startDate || !endDate) return; // Ensure dates are set before fetching data
    const fetchData = async () => {
      try {
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');

        const payload = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          filterBy: {
            period: alignment === 'weekly' ? 'week' : 'month',
          },
        };
        const response = await fetchUserInteractions(payload);
        const responseData = response.data.data;

        setData(responseData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user interaction:', error);
      }
    };

    fetchData();
  }, [startDate, endDate, alignment, selectedData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowAllData = () => {
    setShowToggel(true);
    onChartClick('UserInteractions', data, true);
  };

  const truncateLabel = (label) => (label.length > 30 ? `${label.substring(0, 17)}...` : label);

  const limitedChartData = {
    labels: data.slice(0, 10).map((item) => truncateLabel(item.duration)),
    datasets: [
      {
        data: data.slice(0, 10).map((item) => item.count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartData = {
    labels: data.map((item) => item.duration),
    datasets: [
      {
        data: data.map((item) => item.count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
        hoverBackgroundColor: [
          'rgba(255, 99, 132, 0.4)',
          'rgba(54, 162, 235, 0.4)',
          'rgba(255, 206, 86, 0.4)',
          'rgba(75, 192, 192, 0.4)',
        ],
        barPercentage: 0.3,
        categoryPercentage: 0.7,
      },
    ],
  };
  const maxDataValue = Math.max(...data.map((item) => item.count));
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: maxDataValue + 20,
      },
      x: {
        ticks: {
          align: 'start', // Align the labels to the start (left)
          maxWidth: 200, // Adjust the max width to ensure labels fit within the chart area
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
        formatter: (value) => value,
        color: 'black',
      },
      legend: {
        display: false,
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        setDetailsLoading(true);
        const { index } = elements[0];
        const selectedDuration = data[index];
        onChartClick('UserInteractions', selectedDuration, false);
        setShowToggel(false);
        setDetailsLoading(false);
        console.log('toggel', showToggel);
      }
    },
  };

  let displayedChartData;
  if (showAllData) {
    displayedChartData = chartData;
  } else if (selectedData) {
    displayedChartData = {
      labels: [selectedData.duration],
      datasets: [
        {
          data: [selectedData.count],
          backgroundColor: [
            'rgba(255, 99, 132, 0.7)',
            'rgba(54, 162, 235, 0.7)',
            'rgba(255, 206, 86, 0.7)',
            'rgba(75, 192, 192, 0.7)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  } else {
    displayedChartData = limitedChartData;
  }

  const paginatedData = showAllData ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data;

  return (
    <Container sx={{ background: '#fff !important', minHeight: 'auto !important' }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="h6"
          gutterBottom
        >
          User Interactions
        </Typography>
        {showToggel && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              mt: 3
            }}
          >
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton
                value="weekly"
                aria-label="weekly"
                sx={{
                  backgroundColor: alignment === 'weekly' ? '#10385D' : 'white',
                  color: alignment === 'weekly' ? 'white' : '#10385D',
                  '&.Mui-selected': {
                    backgroundColor: '#10385D',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#10385D',
                      color: 'white',
                    },
                  },
                  '&:hover': {
                    backgroundColor: '#10385D',
                    color: 'white',
                  },
                  borderRadius: '10px',
                  padding: '2px 10px',
                  margin: '0 1px',
                  border: '1px solid #10385D',
                  textTransform: 'none',
                  fontSize: '0.875rem',
                }}
              >
                Weekly
              </ToggleButton>
              <ToggleButton
                value="monthly"
                aria-label="monthly"
                sx={{
                  backgroundColor: alignment === 'monthly' ? '#10385D' : 'white',
                  color: alignment === 'monthly' ? 'white' : '#10385D',
                  '&.Mui-selected': {
                    backgroundColor: '#10385D',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#10385D',
                      color: 'white',
                    },
                  },
                  '&:hover': {
                    backgroundColor: '#10385D',
                    color: 'white',
                  },
                  borderRadius: '10px',
                  padding: '2px 10px',
                  margin: '0 1px',
                  border: '1px solid #10385D',
                  textTransform: 'none',
                  fontSize: '0.875rem',
                }}
              >
                Monthly
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
      </Grid>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CubeSpinner
            size={40}
            frontColor="#ff6384"
            backColor="#36a2eb"
            leftColor="#ffcd56"
            rightColor="#4bc0c0"
            topColor="#9966ff"
            bottomColor="#ff9f40"
          />
        </div>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={selectedData || showAllData ? 6 : 12}>
            {showAllData ? (
              <div style={{ height: '300px', overflowX: 'scroll' }} ref={chartContainerRef}>
                <div style={{ height: `${Math.max(data.length * 40, 320)}px` }}>
                  <Bar
                    data={displayedChartData}
                    options={options}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            ) : (
              <div style={{ height: '260px', overflowX: 'auto' }} ref={chartContainerRef}>
                <Bar
                  data={displayedChartData}
                  options={options}
                  plugins={[ChartDataLabels]}
                />
              </div>
            )}
            {showAllData ? '' : (
              <Button
                variant="contained"
                className="chartsButtons"
                onClick={handleShowAllData}
                sx={{
                  margin: '0',
                  padding: '2px 6px',
                  fontSize: '0.75rem',
                  width: '70px',
                  height: '31px',
                }}
              >
                {showAllData ? 'Show Less' : 'Show All'}
              </Button>
            )}
          </Grid>
          {(selectedData || showAllData) && (
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                {showAllData ? 'Complete Data' : `Duration Details: ${selectedData ? selectedData.duration : ''}`}
              </Typography>
              {detailsLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                  <CubeSpinner
                    size={40}
                    frontColor="#ff6384"
                    backColor="#36a2eb"
                    leftColor="#ffcd56"
                    rightColor="#4bc0c0"
                    topColor="#9966ff"
                    bottomColor="#ff9f40"
                  />
                </div>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Duration</TableCell>
                          <TableCell>Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {showAllData && paginatedData.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.duration}</TableCell>
                            <TableCell>{item.count}</TableCell>
                          </TableRow>
                        ))}
                        {!showAllData && selectedData && (
                          <TableRow>
                            <TableCell>{selectedData.duration}</TableCell>
                            <TableCell>{selectedData.count}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={showAllData ? data.length : data.slice(0, 10).length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableContainer>
                </>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  );
};
UserInteractions.propTypes = {
  onChartClick: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
  showAllData: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
export default UserInteractions;
