/* eslint-disable */
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Avatar,
  Button,
  List,
  ListItem,
  Typography,
  alpha,
  useMediaQuery,
  CircularProgress,
  ListSubheader,
} from '@material-ui/core';
import { useDispatch, useSelector } from '../../../store';
import ChatThreadItem from './ChatThreadItem';
import { addModel, addNewThread, setThreadMountedStatus, toggleSidebar, resetActiveThread, toggleIsDocumentViewerOpen } from 'src/slices/chat';
import { Theme } from '@mui/material';
import toast from 'react-hot-toast';
import i18n from 'src/i18n';
import colors from 'src/theme/colors';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import NewChatIcon from 'src/icons/NewChatIcon';

const ChatThreadList: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(760));
  const { loadingThreads, threads, activeThreadId, selectedModel, isCallInProgress } = useSelector((state) => state.chat);
  // const {
  //   activeThreadId,
  //   threadNoLongerExist,
  //   loadingThreads,
  //   selectedModel,
  //   message,
  //   showSideBar
  // } = useSelector((state) => state.chat);
  const { userProfile = {} } = useSelector((state) => state.user);
  const { id } = userProfile;
  const navigate = useNavigate();

  const isTypeInProgress = () => threads?.byId?.[activeThreadId]?.message?.some(
    (message) => !message?.showIcons && isEmpty((message as any)?.senderId)
  );

  let toastId = null;
  let msgLoading = false;
  if (!isEmpty(threads?.byId[activeThreadId])) {
    msgLoading = threads?.byId[activeThreadId]?.msgLoading;
  }

  const handleSelect = (threadId: string): void => {
    if (isCallInProgress || isTypeInProgress()) {
      const msg = i18n.t('callInProgress');
      if (toastId) {
        toast.dismiss(toastId);
      }

      toastId = toast(msg, {
        duration: 2000,
        style: {
          // border: '2px solid red',
        }
      });
      return;
    }

    const thread = threads.byId[threadId];
    dispatch(addModel(thread.model));
    dispatch(toggleIsDocumentViewerOpen(false));
    if (smDown) {
      dispatch(toggleSidebar(false));
    }
    navigate(`/home/${threadId}`);
  };

  const handleNewChatClick = async () => {
    try {
      await dispatch(resetActiveThread());
      // Navigate to '/home' on success
      navigate('/home');
    } catch (error) {
      // Handle error, you can log it or show a message to the user
      console.error('An error occurred:', error);
    }
  };

  if (loadingThreads) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress size={22} style={{ color: colors.white }} />
      </Box>
    );
  }

  return (
    <>
      <List
        sx={{ pt: 0 }}
        subheader={
          <>{<ListSubheader sx={{ backgroundColor: '#10385D', p: 0, m: 0 }} component="div" id="nested-list-subheader">
            <Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                }}
              >
                <Button
                  color="primary"
                  className={`btn-newChat ${msgLoading || threads?.byId[activeThreadId]?.msgTyping
                    ? 'disabled'
                    : ''
                    }`}
                  variant="outlined"
                  onClick={handleNewChatClick}
                  disabled={
                    msgLoading || threads?.byId[activeThreadId]?.msgTyping
                  }
                >
                  {t('newChat')}
                  <NewChatIcon color='white' fontSize="small" />
                </Button>
              </Box>
            </Box>
          </ListSubheader>}
          </>}
      >
        {threads.allIds.map((threadId) => (
          <>
            <ChatThreadItem
              active={activeThreadId === threadId}
              key={threadId}
              onSelect={(): void => handleSelect(threadId)}
              thread={threads.byId[threadId]}
              threads={threads}
            />
          </>
        ))}
      </List>
    </>
  );
};

export default ChatThreadList;
