/*eslint-disable*/
import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  IconButton,
  Input,
  ListItem,
  Tooltip,
  Typography,
  alpha,
} from '@material-ui/core';
import { Thread } from 'src/types/chat';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { removeThreadByid } from 'src/slices/chat';
import { useDispatch } from 'react-redux';
// import DeleteIcon from 'src/icons/DeleteIcon';
import colors from 'src/theme/colors';
// import ChatDeleteDialog from './ChatDeleteDialog';
import { RootState, useSelector } from 'src/store';
import { deleteThreadById } from 'src/api/chat';
import { useTranslation } from 'react-i18next';

interface ChatThreadItemProps {
  active?: boolean;
  onSelect: () => void;
  thread: Thread;
  threads: any;
}

const ChatThreadItem: FC<ChatThreadItemProps> = (props) => {
  const { active, thread, onSelect, threads, ...other } = props;
  const isCallInProgress = useSelector((state: RootState) => state.chat.isCallInProgress);
  // const { messageLoading } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const [disableChatDeleteOkButton, setDisableChatDeleteOkButton] = useState(false);
  const { t, i18n: lang } = useTranslation();
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [editingTitleText, setEditingTitleText] = useState<string>('');

  // useEffect(() => {
  //   setHovered(active);
  // }, [active]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  // const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   if (e.code.toLowerCase() === 'enter' || e.code.toLowerCase() === 'numpadenter') {
  //     handleSendUpdatedTitle(editingTitleText, thread.external_chat_id);
  //   } else {
  //     if(e.code.toLowerCase() === 'space') {
  //       if(editingTitleText.slice(-1) === " ") {
  //         e.preventDefault();
  //       }
  //     }
  //   }
  // }

  // const handleOnBlur = () => {
  //   if (thread.external_chat_id) {
  //     handleSendUpdatedTitle(editingTitleText, thread.external_chat_id);
  //   }
  // };

  // const handleSendUpdatedTitle = (updatedTitle: string, threadId: string) => {
  //   if (updatedTitle.trim().length && updatedTitle.trim().length < 256) {
  //     dispatch(updateChatTitle(updatedTitle.trim(), threadId));
  //     setIsEditingTitle(false);
  //     setEditingTitleText('');
  //   } else {
  //     setIsEditingTitle(false);
  //     setEditingTitleText('');
  //   }
  // }

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        py: '3px',
        pr: '11px',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        my: 0,
        height: {
          xs: 32,
          sm: 32,
          md: 35,

          lg: 40,
          xl: 40,
        },
        justifyContent: 'space-between',
        backgroundColor: (active) && '#1B5E9B',
        '&:hover': {
          backgroundColor: (active) && '#1B5E9B'
        },
      }}
    >
      <ListItem
        button
        disableRipple
        // disabled={messageLoading}
        onClick={onSelect}
        sx={{
          padding: 0,
          borderRadius: '4px',
          m: 1,
          minWidth: '82%',
          maxWidth: '94%',
          backgroundColor: 'unset', // Remove the background color
          '&:hover': {
            backgroundColor: 'unset', // Remove the background color for hover state
          },
          '&:active': {
            backgroundColor: 'unset', // Remove the background color for active state
          },
        }}
        style={{ margin: '0px' }}
        {...other}
      >
        <Avatar
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0),
            color: 'white',
            mr: 0.1,
            ml: 0.4,
          }}
        >
          <ChatBubbleOutlineIcon
            style={{
              color: colors.white
            }}
            sx={{
              fontSize: {
                xs: '1rem',
                sm: '1rem',
                md: '1.125rem',
                lg: '1.375rem',
                xl: '1.375rem'
              }
            }}
          />
        </Avatar>
        {
          <Tooltip title={thread.title}>
            <Typography
              sx={{
                mb: 0,
                mr: 1,
                fontSize: {
                  xs: '0.75rem',
                  sm: '0.75rem',
                  md: '0.75rem',
                  lg: '0.875rem',
                  xl: '1rem'
                }
              }}
              color={colors.white}
              gutterBottom
              variant="subtitle2"
              noWrap
            >
              {thread.title}
            </Typography>
          </Tooltip>
          // <Input
          //   onBlur={handleOnBlur}
          //   value={editingTitleText}
          //   disableUnderline
          //   sx={{ color: 'white' }}
          //   autoFocus={isEditingTitle}
          //   onKeyDown={(e) => handleTitleKeyDown(e)}
          //   onChange={(e) => setEditingTitleText(e.target.value)}
          // />
        }
      </ListItem>
    </Box>
  );
};

ChatThreadItem.propTypes = {
  active: PropTypes.bool,
  onSelect: PropTypes.func,
  // @ts-ignore
  thread: PropTypes.object.isRequired,
  threads: PropTypes.any,
};

ChatThreadItem.defaultProps = {
  active: false,
};

export default ChatThreadItem;
