import { FC, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { BooleanSchema } from 'yup';

interface CustomScrollbarProps {
  children: React.ReactNode;
  ignoreHeight?: boolean,
}

const CustomScrollbar = forwardRef<HTMLDivElement, CustomScrollbarProps>((props: CustomScrollbarProps, ref) => {
  const { children, ignoreHeight = false, ...other } = props;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  if (isMobile) {
    return (
      <div
        ref={ref}
        style={{ overflow: 'auto', height: ignoreHeight ? 'unset' : '100%' }}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      ref={ref}
      style={{ overflow: 'auto', height: ignoreHeight ? 'unset' : '100%' }}
      {...other}
    >
      {children}
    </div>
  );
});

CustomScrollbar.propTypes = {
  children: PropTypes.node,
  ignoreHeight: PropTypes.bool
};

export default CustomScrollbar;
