import PropTypes from 'prop-types';
import type { FC } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Paper,
  Typography
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import { useTranslation } from 'react-i18next';

interface SessionTimeoutProps {
  onClick: () => void;
  message: string;
}

const SessionTimeout: FC <SessionTimeoutProps> = ({ onClick, message }) => {
  const { t, i18n: lang } = useTranslation();
  return (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        minHeight: '100%',
        p: 3
      }}
    >
      <Container maxWidth="sm">
        <Paper elevation={12}>
          <Box
            sx={{
              display: 'flex',
              pb: 2,
              pt: 3,
              px: 3
            }}
          >
            <Avatar
              sx={{
                backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                color: 'error.main',
                mr: 2
              }}
            >
              <WarningIcon />
            </Avatar>
            <Box>
              <Typography
                sx={{ '&.MuiTypography-h5': {
                  color: '#2B5D8C',
                  fontFamily: 'Lato'
                }
                }}
                variant="h5"
              >
                {t('sessionTimedOut.title')}
              </Typography>
              <Typography
                color="#000000"
                sx={{ mt: 1 }}
                variant="body2"
              >
                {message}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              px: 3,
              py: 1.5
            }}
          >
            <Button
              sx={{
                backgroundColor: 'error.main',
                '&:hover': {
                  backgroundColor: 'error.dark'
                },
                borderRadius: 4,
              }}
              variant="contained"
              onClick={onClick}
            >
              {t('ok')}
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

SessionTimeout.propTypes = {
  onClick: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export default SessionTimeout;
