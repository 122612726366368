/*eslint-disable*/
import { Rating, Stack, CircularProgress } from '@material-ui/core';
import { ArrowDropDownSharp } from '@material-ui/icons';

import {
  Box,
  Divider,
  Typography,
} from '@material-ui/core';

import RTL from '../../../RTL';
import colors from 'src/theme/colors';

export default function ScoreTypeView({ answerRelevance, contextRelevance, groundedness }) {

  const maxScore = 10;

  return (
    <RTL name="DialogView" direction="ltr">
      <Stack spacing={1} direction="row">
        <Typography variant="h6" sx={{ paddingRight: '28px' }}>
          Accuracy
        </Typography>
        <ArrowDropDownSharp sx={{ paddingTop: '5px' }} />
      </Stack>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        {(answerRelevance < 0 || contextRelevance < 0 || groundedness < 0) && <CircularProgress />}
      </Box>

      <Stack spacing={1} direction="row" sx={{ textAlign: 'center', justifyContent: 'center' }}>
        {answerRelevance >= 0 && (
          <>
            <Typography sx={{ fontSize: '.875rem', background: colors.lightGreyFiftyPercent }}>
              <b>Answer Relevance:</b> {`${answerRelevance}/${maxScore}`}
            </Typography>
            <Rating name="half-rating-read" value={answerRelevance / 2} precision={1} readOnly />
          </>
        )}
        <Divider orientation="vertical" flexItem />
        {
          contextRelevance >= 0 && (
            <>
              <Typography sx={{ fontSize: '.875rem', background: colors.lightGreyFiftyPercent }}>
                <b>Context Relevance:</b> {`${contextRelevance}/${maxScore}`}
              </Typography>
              <Rating name="half-rating-read" value={contextRelevance / 2} precision={1} readOnly />
            </>
          )
        }

        <Divider orientation="vertical" flexItem />
        {groundedness >= 0 && (
          <>
            <Typography sx={{ fontSize: '.875rem', background: colors.lightGreyFiftyPercent }}>
              <b>Groundedness:</b> {`${groundedness}/${maxScore}`}
            </Typography>
            <Rating name="half-rating-read" value={groundedness / 2} precision={1} readOnly />
          </>
        )}

      </Stack>
      <Divider />
    </RTL>
  );
}
