/*eslint-disable*/
import { Suspense, lazy } from 'react';
import { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import AdminLayout from './admin/components/adminLayout/AdminLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import { useSelector } from './store';
import MainLayout from './components/MainLayout';
import AdminAuthGuard from './components/AuthAdminGuard';
import { ConfigurationsList } from './components/dashboard/configurations';
import SocialVerification from './pages/authentication/SocialVerification';
import LandingChatPage from './pages/dashboard/LandingChatPage';
import WebsiteEditForm from './admin/components/dashboard/content/WebsiteEditForm';
import UsersList from './admin/pages/dashboard/users/UsersList';
import DashboardAnalytics from './admin/pages/dashboard/analytics/Analytics';

const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// SSO-Redirect pages
const SsoRedirect = Loadable(lazy(() => import('./pages/dashboard/SsoRedirect')));

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery'))
);
const PasswordReset = Loadable(
  lazy(() => import('./pages/authentication/PasswordReset'))
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/Register'))
);
const VerifyCode = Loadable(
  lazy(() => import('./pages/authentication/VerifyCode'))
);
const SocialRegister = Loadable(
  lazy(() => import('./pages/authentication/SocialRegister'))
);
const Logout = Loadable(lazy(() => import('./pages/authentication/Logout')));

// Dashboard pages
const LandingPage = Loadable(
  lazy(() => import('./pages/dashboard/LandingPage'))
);

// Admin pages
const Overview = Loadable(lazy(() => import('./admin/pages/admin/Overview')));
const CustomerEdit = Loadable(
  lazy(() => import('./admin/pages/dashboard/customers/CustomerEdit'))
);
const CustomerList = Loadable(
  lazy(() => import('./admin/pages/dashboard/customers/CustomerList'))
);
const RolesEdit = Loadable(
  lazy(() => import('./admin/pages/dashboard/roles/RolesEdit'))
);
const RolesAdd = Loadable(
  lazy(() => import('./admin/pages/dashboard/roles/RolesAdd'))
);
const RolesList = Loadable(
  lazy(() => import('./admin/pages/dashboard/roles/RolesList'))
);
const ManageContent = Loadable(
  lazy(() => import('./admin/pages/dashboard/content/ManageContent'))
);
const EditContent = Loadable(
  lazy(() => import('./admin/pages/dashboard/content/EditContent'))
);

// Error pages
const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));
const TermsOfUse = Loadable(lazy(() => import('./pages/TermsOfUse')));
const PrivacyPolicy = Loadable(lazy(() => import('./pages/PrivacyPolicy')));

function PrivateRoute() {
  const { isAuthenticated = false } = useSelector((state) => state.auth);

  if (isAuthenticated) {
    return <Navigate to="/admin/contents/new" replace />;
  }
  return <Navigate to="/auth/login" replace />;
}

const routes: PartialRouteObject[] = [
  {
    path: '/',
    element: <PrivateRoute />,
  },
  {
    path: 'sso-redirect',
    element: (
      <GuestGuard>
        <SsoRedirect />
      </GuestGuard>
    )
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'social-register',
        element: (
          <GuestGuard>
            <SocialRegister />
          </GuestGuard>
        ),
      },
      {
        path: 'social-verification',
        element: (
          <GuestGuard>
            <SocialVerification />
          </GuestGuard>
        ),
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
    ],
  },
  {
    path: 'home',
    element: (
      <AuthGuard>
        <LandingChatPage />
      </AuthGuard>
    ),
  },
  {
    path: 'home/:threadKey',
    element: (
      <AuthGuard>
        <LandingChatPage />
      </AuthGuard>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'configurations',
        element: <ConfigurationsList />,
      },
    ],
  },
  {
    path: 'admin',
    element: (
      <AdminAuthGuard>
        <AdminLayout />
      </AdminAuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      {
        path: 'contents',
        children: [
          {
            path: 'new',
            element: (
              <AdminAuthGuard>
                <ManageContent />
              </AdminAuthGuard>
            ),
          },
          {
            path: ':externalUid/edit',
            element: (
              <AdminAuthGuard>
                <EditContent />
              </AdminAuthGuard>
            ),
          },
        ],
      },
      {
        path: 'customers',
        children: [
          {
            path: '',
            element: <CustomerList />,
          },
          {
            path: ':customerId/edit',
            element: <CustomerEdit />,
          },
          {
            path: 'editbyemail',
            element: <CustomerEdit />,
          },
        ],
      },
      {
        path: "users",
        children: [{
          path: '',
          element: (
            <AdminAuthGuard>
              <UsersList />
            </AdminAuthGuard>
          )
        }]
      },
      {
        path: "analytics",
        children: [{
          path: '',
          element: (
            <AdminAuthGuard>
              <DashboardAnalytics />
            </AdminAuthGuard>
          )
        }]
      },
      {
        path: 'roles',
        children: [
          {
            path: '',
            element: (
              <GuestGuard>
                <RolesList />
              </GuestGuard>
            ),
          },
          {
            path: ':roleId/edit',
            element: (
              <GuestGuard>
                <RolesEdit />
              </GuestGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <GuestGuard>
                <RolesAdd />
              </GuestGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'policies',
    children: [
      {
        path: 'terms-of-use',
        element: <TermsOfUse />
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
