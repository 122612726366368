/*eslint-disable*/
import type { FC } from 'react';
import {
  useMediaQuery,
  FormControl,
  Theme,
  Typography,
  Box,
  IconButton,
  MenuItem,
  Select
} from '@material-ui/core';
import SimpleCard from './ChatCard';
import { useTranslation } from 'react-i18next';
import QUESTION_BOX from 'src/icons/QuestionBox.svg';
import colors from 'src/theme/colors';
import { ChatMessageWidth } from 'src/constants';

interface ChatThreadComposerProps { }

const ChatThreadComposer: FC<ChatThreadComposerProps> = (props) => {
  const { ...other } = props;
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(760));

  const { t } = useTranslation();

  return (
    <>
      <div className='content-wrapper'>
        <div className='upper-section'>
          {/* <Box className='title-wrapper'>
            <Typography
              component='span'
              className='title'
              color="primary.main"
            >
              {t("askDEWA")}
            </Typography>
          </Box> */}
          {/* <Box className='dropdowns-wrapper'>
            <Box className='dropdown-section'>
              <Typography
                component='span'
                className='dd-title'
                color="primary.dark"
              >
                {t("dewaChat.selectAModel")}
              </Typography>
              <FormControl>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    }
                  }}
                  value={"40b"}
                  color='primary'
                >
                  <MenuItem value="40b">DEWA 40b</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className='dropdown-section middle'>
              <Typography
                component='span'
                className='dd-title'
                color="primary.dark"
              >
                {t("dewaChat.SelectAnEmbeddingsModel")}
              </Typography>
              <FormControl>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    }
                  }}
                  value={"1"}
                >
                  <MenuItem value="1">Hugging Face (sentence-transformers)</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box className='dropdown-section'>
              <Typography
                component='span'
                className='dd-title'
                color="primary.dark"
              >
                {t("dewaChat.SelectASource")}
              </Typography>
              <FormControl>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    }
                  }}
                  value={"TAMM"}
                >
                  <MenuItem value="TAMM">TAMM</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box> */}
        </div>
      </div>
      <Box
        sx={{
          alignItems: 'center',
          // display: 'flex',
          height: 'calc(100% - 120px)',
          width: '100%',
          justifyContent: 'center',
          flexDirection: 'column', // Change to column direction
          display: {
            xs: 'none',
            sm: 'none',
            md: 'flex',
            lg: 'flex',
            xl: 'flex',
          }
        }}
        {...other}
      >
        {!smDown && <Box
          sx={{
            width: ChatMessageWidth,
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
        </Box>}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            p: 9,
          }}
          {...other}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <IconButton
              disableRipple
              sx={{
                backgroundColor: colors.mainColor,
                padding: '0px',
                cursor: 'default',
                mb: 0.6,
                '&:hover': {
                  backgroundColor: colors.mainColor,
                },
              }}
            >
              <img src={QUESTION_BOX} />
            </IconButton>
            <Typography
              sx={{
                color: '#2B5D8C',
                fontSize: {
                  xs: '0.875rem',
                  sm: '1rem',
                  md: '1rem',
                  lg: '1.25rem',
                  xl: '1.5rem'
                },
                fontWeight: {
                  xs: '600',
                  sm: '600',
                  md: '600',
                  lg: '600',
                  xl: '700'
                },
                letterSpacing: '0.44px'
              }}
              color="textSecondary"
            >
              {t('sampleQuestions')}
            </Typography>
          </Box>
          <Box
            sx={{
              m: 0.2,
              display: 'grid',
              gap: '20px',
              gridTemplateColumns: {
                xs: '1fr',
                sm: '1fr 1fr',
                md: '1fr 1fr',
                lg: '1fr 1fr 1fr',
              },
            }}
          >
            <SimpleCard text="What is HidrateSpark?" />
            <SimpleCard text="How can I contact HidrateSpark?" />
            <SimpleCard text="What are the core values of HidrateSpark?" />
          </Box>
        </Box>
      </Box></>
  );
};

ChatThreadComposer.propTypes = {};
ChatThreadComposer.defaultProps = {};

export default ChatThreadComposer;
