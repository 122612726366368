import { MutableRefObject, useEffect, useRef, useState } from 'react';

type HoverData = [MutableRefObject<any>, boolean];

const useHover = (): HoverData => {
  const [value, setValue] = useState(false);
  const ref = useRef(null);

  const handleMouseMove = () => {
    setValue(true);
  };

  const handleMouseLeave = () => {
    setValue(false);
  };

  /* eslint-disable */
  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('mousemove', handleMouseMove);
      node.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        node.removeEventListener('mousemove', handleMouseMove);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [ref.current]);

  return [ref, value];
};

export default useHover;
