/*eslint-disable*/
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeRaw from 'rehype-raw'; // Import rehype-raw
import { markMessageAsSeen } from 'src/slices/chat';

const ChatMessageLoaderTypingMarkdown = ({ threadId, msgId, content, speed, showTyping, onTypingComplete }) => {

  const dispatch = useDispatch();
  const [displayText, setDisplayText] = useState('');
  const typingSpeed = speed ? speed : 50;

  useEffect(() => {
    setDisplayText('');
  }, [threadId]);

  const addMessageAsSeen = async () => {
    if (displayText) {
      await dispatch(
        markMessageAsSeen(threadId, msgId, true)
      );
    }
  };

  useEffect(() => {
    if (showTyping) {
      let currentIndex = displayText.length; //managing stale state
      let interval;

      clearInterval(interval);

      const typeText = () => {
        if (currentIndex < content.length) {
          setDisplayText((prevText) => {
            if( prevText === content ) {
              return prevText;
            }
            return prevText + content[currentIndex]
          });
          currentIndex+=1;
        } else {
          clearInterval(interval);
          if (onTypingComplete) {
            onTypingComplete();
          }
        }
      };

      interval = setInterval(typeText, typingSpeed);

      return () => {
        addMessageAsSeen();
        clearInterval(interval);
      };
    } else {
      setDisplayText(content);
    }
  }, [content, showTyping, onTypingComplete, msgId]);

  return (
    <div className={`message__markdown text-left`}>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <SyntaxHighlighter
                {...props}
                children={String(children).replace(/\n$/, '')}
                style={dark}
                language={match[1]}
                PreTag="div"
              />
            ) : (
              <code {...props} className={className}>
                {children}
              </code>
            );
          },
        }}
      >
        {displayText}
      </ReactMarkdown>
    </div>
  );
};

export default ChatMessageLoaderTypingMarkdown;
