// api.js
import API from '../../../api/apiInstance';
import { AnalyticsSummary, AnalyticsUserIntraction } from './types';

export const fetchAnalyticsSummary = (payload: AnalyticsSummary) => API.post('/analytics/summary/', payload);

export const fetchUserUtterances = (payload: AnalyticsSummary) => API.post('/analytics/conversation/top-user-questions', payload);
export const fetchBotResponses = (payload: AnalyticsSummary) => API.post('/analytics/conversation/top-bot-responses', payload);
export const fetchTopDocumentsHitted = (payload: AnalyticsSummary) => API.post('/analytics/document/top-document-sources', payload);
export const fetchLatencyAndTokens = (payload: AnalyticsSummary) => API.post('/analytics/llm/execution-info', payload);
export const fetchUserInteractions = (payload: AnalyticsUserIntraction) => API.post('/analytics/conversation/user-interactions-by-period', payload);
