/* eslint-disable */
// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../store';
import type { MessagePayload, Thread } from '../types/chat';
import objFromArray from '../utils/objFromArray';
import { getThreadsList, addMessage, getConversationById, getChatAccuracySetting, getChatQuestionsList } from 'src/api/chat';
import createResourceId from 'src/utils/createResourceId';
import deepCopy from '../utils/deepCopy';
// import { DEWA_40B_ID } from 'src/components/dashboard/SelectModel';
import i18n from 'src/i18n';
import { isEmpty } from 'lodash';
import { CHAT_ANSWER_LOADER, RESPONSE_MODEL_TYPES } from 'src/constants';

const mapFromArray = (arr, key = 'id') => arr.reduce(
  (map, current) => {
    map.set(current[key], current);
    return map;
  },
  new Map()
);

const now = new Date();
export const DEWA_40B_ID = '';

interface ChatState {
  activeThreadId?: string;
  showSideBar?: boolean;
  loadingThreads?: boolean;
  selectedModel: string;
  selectedModelType: string;
  message?: string;
  threads: {
    byId: Record<string, Thread>;
    allIds: string[];
  };
  isCallInProgress: boolean;
  threadNoLongerExist: boolean;
  isDocumentViewerOpen: boolean;
  isShowAccuracy: boolean;
  chatQuestions: string[];
}

const initialState: ChatState = {
  activeThreadId: null,
  showSideBar: false,
  loadingThreads: true,
  selectedModel: DEWA_40B_ID,
  selectedModelType: RESPONSE_MODEL_TYPES[0].value,
  message: '',
  threadNoLongerExist: false,
  threads: {
    byId: {},
    allIds: [],
  },
  isCallInProgress: false,
  isDocumentViewerOpen: false,
  isShowAccuracy: false,
  chatQuestions: []
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addThread(state: ChatState, action: PayloadAction<Thread[]>): void {
      const threads = action.payload;
      const myNewthread = [
        {
          id: '5e867eb9de721aecaccf4f7eeee',
          messages: [],
          participants: [],
          type: 'ONE_TO_ONE',
          unreadCount: 2,
        },
      ];
      state.threads.byId = objFromArray(myNewthread);
      state.threads.allIds = Object.keys(state.threads.byId)?.map(x => x);
    },
    getThreads(state: ChatState, action: PayloadAction<Thread[]>): void {
      const threads = action.payload;
      state.threads.byId = objFromArray(threads);
      state.threads.allIds = Object.keys(state.threads.byId);
    },
    threadsLoadingStatus(state: ChatState, action: PayloadAction<boolean>): void {
      state.loadingThreads = action.payload;
    },
    addNewThread(state: ChatState, action: PayloadAction<Thread[]>): void {
      const threads = action.payload;
      const oldthreads = deepCopy(state.threads.byId);
      const newthread = objFromArray(threads);

      state.threads.byId = { ...newthread, ...oldthreads };
      state.threads.allIds = Object.keys(state.threads.byId);
      // eslint-disable-next-line
      state.activeThreadId = threads[0]?.['external_chat_id'];
    },
    getThread(state: ChatState, action: PayloadAction<Thread | null>): void {
      const thread = action.payload;

      if (thread) {
        state.threads.byId[thread.external_chat_id] = thread;

        if (!state.threads.allIds.includes(thread.external_chat_id)) {
          state.threads.allIds.push(thread.external_chat_id);
        }

        state.activeThreadId = thread.external_chat_id;
      } else {
        state.activeThreadId = null;
      }
    },
    threadLoadingStatus(state: ChatState, action: any): void {
      const { threadKey, loading } = action.payload;
      if (state.threads.allIds.includes(threadKey)) {
        state.threads.byId[threadKey].loading = loading;
      }
    },
    threadMountedStatus(state: ChatState, action: any): void {
      const { threadKey, status } = action.payload;
      if (state.threads.allIds.includes(threadKey)) {
        state.threads.byId[threadKey].isMounted = status;
      }
    },
    getThreadByKey(state: ChatState, action: PayloadAction<string>): void {
      const threadId = action.payload;
      const thread = state.threads.byId[threadId];

      if (thread) {
        state.threads.byId[thread.external_chat_id] = thread;

        if (!state.threads.allIds.includes(thread.external_chat_id)) {
          state.threads.allIds.push(thread.external_chat_id);
        }

        state.activeThreadId = thread.external_chat_id;
      } else {
        state.activeThreadId = null;
      }
    },
    removeThreadByid(state: ChatState, action: PayloadAction<string>): void {
      const threadId = action.payload;
      const { byId, allIds } = state.threads;
      const updatedById = { ...byId };
      delete updatedById[threadId];

      const updatedAllIds = allIds.filter((id) => id !== threadId);

      state.threads.byId = updatedById;
      state.threads.allIds = updatedAllIds;
      state.activeThreadId = null; // keys[0];
    },
    markMessageAsSeen(state: ChatState, action: PayloadAction<any>): void {
      const { threadId, messageId, showIcons } = action.payload;
      const thread = state.threads.byId[threadId];

      if (thread) {
        const messageIndex = thread.message?.findIndex((msg) => msg.id === messageId);
        if (messageIndex !== -1) {
          const updatedMessage = {
            ...thread.message[messageIndex],
            showIcons
          };
          thread.message[messageIndex] = updatedMessage;
        }
      }
    },
    resetActiveThread(state: ChatState): void {
      state.activeThreadId = null;
    },
    resetMessageThread(state: ChatState): void {
      state.message = '';
    },
    updateThreadExternalId(state, action: any): void {
      const { oldThreadId, newThreadId } = action.payload;
      const threads = deepCopy(Object.values(state.threads.byId));
      const thread = threads?.find(t => t.id === oldThreadId || t.external_chat_id === oldThreadId);
      if (thread) {
        thread.external_chat_id = newThreadId;
        delete thread.id;
      }

      state.threads.byId = objFromArray(threads);

      state.threads.allIds = Object.keys(state.threads.byId);
    },
    resetChat(state: ChatState): void {
      state.selectedModel = DEWA_40B_ID;
      state.selectedModelType = RESPONSE_MODEL_TYPES[0].value;
      state.message = '';
      state.threads = {
        byId: {},
        allIds: [],
      };
      state.activeThreadId = null;
      // changed to false was true previously
      state.showSideBar = false;
      state.loadingThreads = true;
      state.message = '';
      state.isCallInProgress = false;
      state.threadNoLongerExist = false;
    },
    addModel(state: ChatState, action: PayloadAction<any>): void {
      const selectedModel = action.payload;
      state.selectedModel = selectedModel;
    },
    setModelType(state: ChatState, action: PayloadAction<any>): void {
      state.selectedModelType = action.payload;
    },
    addMessageInThread(state: ChatState, action: any): void {
      const { message, activeThreadId, msgLoading } = action.payload;
      if (state.threads.byId[activeThreadId]) {
        state.threads.byId[activeThreadId].message?.push(message);
        state.threads.byId[activeThreadId].msgLoading = msgLoading;
      }
      state.isCallInProgress = msgLoading;
    },
    updateMessageByIdInThreadWithAnswer(state: ChatState, action: any): void {
      let state1 = state;
      const { source_documents, msgResourceId, newMsgResourceId, answer, activeThreadId, msgLoading } = action.payload;
      const thread = state.threads.byId[activeThreadId];

      if (thread) {
        const messageIndex = thread.message.findIndex((msg) => msg.id === msgResourceId);
        if (messageIndex !== -1) {
          let newResId = msgResourceId;
          if (!msgResourceId.length === 22) {
            newResId = newMsgResourceId;
          }
          const updatedMessage = {
            ...thread.message[messageIndex],
            id: newResId,
            external_conv_id: newMsgResourceId,
            answer,
            source_documents,
          };
          thread.message[messageIndex] = updatedMessage;
        }
      }

      state.activeThreadId = activeThreadId;
      if (state.threads.byId[activeThreadId]) {
        state.threads.byId[activeThreadId].msgLoading = msgLoading;
      }
      state.isCallInProgress = msgLoading;
    },
    addMessage(state: ChatState, action: any): void {
      const message = action.payload;
      state.message = message;
    },
    toggleSidebar(state: ChatState, action: any): void {
      const sidebar = action.payload;
      state.showSideBar = sidebar;
    },
    updateThreadNoLongerExist(state: ChatState, action: any): void {
      state.threadNoLongerExist = action.payload;;
    },
    updateMessageTyping(state: ChatState, action: any): void {
      const { activeThreadId, msgTyping } = action.payload;
      state.threads.byId[activeThreadId].msgTyping = msgTyping;
    },
    setIsDocumentViewerOpen(state: ChatState, action: PayloadAction): void {
      state.isDocumentViewerOpen = action.payload;
    },
    setIsShowAccuracy(state: ChatState, action: PayloadAction): void {
      state.isShowAccuracy = action.payload;
    },
    setChatQuestions(state: ChatState, action: PayloadAction): void {
      state.chatQuestions = action.payload;
    }
  },
});

export const { reducer } = slice;

export const getThreads =
  (): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      const { loadingThreads, threads: stateThreads } = getState().chat;
      dispatch(slice.actions.threadsLoadingStatus(true));
      let threads = [];
      try {
        if (stateThreads.allIds?.length) {
          threads = Object.values(stateThreads.byId);
        } else {
          const threadsList = await getThreadsList();
          threads = threadsList.data;
        }
      } catch (error) { }

      dispatch(slice.actions.getThreads(threads));
      dispatch(slice.actions.threadsLoadingStatus(false));
    };

export const getChatAccuracySettings =
  (): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      try {
        const accuracySetting = await getChatAccuracySetting();
        var enableAccuracy = (accuracySetting?.data?.toLowerCase?.() === 'true');
        dispatch(slice.actions.setIsShowAccuracy(enableAccuracy));

      } catch (error) { }
    };

export const getChatQuestions =
  (): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      try {
        const questionsResponse = await getChatQuestionsList();
        var questions = questionsResponse?.data;
        dispatch(slice.actions.setChatQuestions(questions));

      } catch (error) { }
    };


const getFirstThreeWords = (paragraph) => {
  try {
    const words = paragraph.split(/\s+/).filter(word => word !== '');
    return words.slice(0, 3).join(' ');
  }
  catch (err) {
    return paragraph
  }
}
export const addNewThread =
  (message = '', model = DEWA_40B_ID): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      let title = '';

      if (!isEmpty(message)) {
        title = message?.replace(/[^\w\sء-ي]/gi, '');
        if (isEmpty(title?.trim())) {
          title = message;
        }
        title = getFirstThreeWords(title);
      }
      // create new hidden thread
      const newThread = {
        external_chat_id: createResourceId(),
        message: [],
        model,
        title,
        isHidden: true,
      }
      const currentState: RootState = getState();
      const threads = deepCopy(Object.values(currentState.chat.threads.byId));
      threads.unshift(newThread);
      await dispatch(slice.actions.addNewThread(threads));
      // message handling
      try {

        await dispatch(addAndGetMessage(message, newThread.external_chat_id));
        // await dispatch(addAndGetMessage(message));
      }
      catch (err) {
      }
    };

export const getThreadByKey =
  (threadId: string): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      const currentState: RootState = getState();

      dispatch(slice.actions.getThreadByKey(threadId));
    };

export const removeThreadByid =
  (threadId: string): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      dispatch(slice.actions.removeThreadByid(threadId));
    };

export const setThreadMountedStatus =
  (threadKey: string, status: boolean): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      dispatch(slice.actions.threadMountedStatus({ threadKey, status }));

    };


export const getThread =
  (threadKey: string): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      let conversation: Thread;
      let threadLoading = true;
      try {
        dispatch(
          slice.actions.threadLoadingStatus({ threadKey, loading: threadLoading })
        );
        const conversationList = await getConversationById(threadKey);
        const conversationResponse: Thread | null = conversationList?.data;
        if (!isEmpty(conversationResponse?.message)) {
          const modifiedMessages = conversationResponse.message.map(
            (message) => ({
              ...message,
              showIcons: true,
            })
          );

          // Replace the original messages with the modified messages
          conversation = {
            ...conversationResponse,
            message: modifiedMessages,
          };
        }
        threadLoading = false;
      } catch (error) {
        if (error?.response?.data?.error_code === 404) {
          dispatch(slice.actions.removeThreadByid(threadKey));
          dispatch(slice.actions.updateThreadNoLongerExist(true));
        }
        const currentState = getState();
        const thread = currentState.chat.threads.byId[threadKey];
        if (!isEmpty(thread)) {
          // loading: false means conversation is loaded now
          // msgLoading means no message is pending from server or in progress, this will disable chat send button
          delete conversation.external_chat_id;
          const newThread = {
            ...thread,
            isMounted: false,
            ...conversation,
            loading: threadLoading,
            msgLoading: false,
          };
          dispatch(slice.actions.getThread(newThread));
        }
        threadLoading = true;
      }
      const currentState = getState();
      const thread = currentState.chat.threads.byId[threadKey];
      if (!isEmpty(thread)) {
        // loading: false means conversation is loaded now
        // msgLoading means no message is pending from server or in progress, this will disable chat send button
        delete conversation.external_chat_id;
        const newThread = {
          ...thread,
          ...conversation,
          loading: threadLoading,
          msgLoading: false,
        };
        dispatch(slice.actions.getThread(newThread));
      }
    };


export const markMessageAsSeen =
  (threadId: string, messageId: string, showIcons = false): AppThunk =>
    async (dispatch): Promise<void> => {
      dispatch(slice.actions.markMessageAsSeen({ threadId, messageId, showIcons }));
    };

export const resetActiveThread =
  () =>
    (dispatch): void => {
      dispatch(slice.actions.resetActiveThread());
    };

export const resetMessageThread =
  () =>
    async (dispatch): Promise<void> => {
      await dispatch(slice.actions.resetMessageThread());
    };

export const resetChat =
  () =>
    async (dispatch): Promise<void> => {
      await dispatch(slice.actions.resetChat());
    };

export const addModel =
  (model: any): AppThunk =>
    async (dispatch): Promise<void> => {
      await dispatch(slice.actions.addModel(model));
    };

export const setModelType =
  (modelType: any): AppThunk =>
    async (dispatch): Promise<void> => {
      await dispatch(slice.actions.setModelType(modelType));
    };

export const toggleSidebar =
  (toggleValue: boolean): AppThunk =>
    (dispatch): void => {
      dispatch(slice.actions.toggleSidebar(toggleValue));
    };

export const toggleIsDocumentViewerOpen =
  (toggleValue: boolean): AppThunk =>
    (dispatch): void => {
      dispatch(slice.actions.setIsDocumentViewerOpen(toggleValue));
    };

export const updateThreadNoLongerExist = (value: boolean): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updateThreadNoLongerExist(value));
  };

const prepareMessage = (question, answer) => ({
  id: createResourceId(),
  question,
  answer,
  // senderId: userId,
  showIcons: false,
});

export const addAndGetMessage =

  (question: string, newThreadId = ''): AppThunk =>
    async (dispatch, getState): Promise<void> => {
      try {

        const currentState = getState();
        const selectedModelType = currentState.chat.selectedModelType;
        let activeThreadId = currentState.chat.activeThreadId || newThreadId;
        const thread = currentState.chat.threads.byId[activeThreadId];
        const userId = currentState.user.userProfile?.id?.toString();
        let message = prepareMessage(question, CHAT_ANSWER_LOADER);
        const msgResourceId = message.id;
        let newMsgResourceId = null;
        dispatch(slice.actions.addMessageInThread({ message, activeThreadId, msgLoading: true }));
        let response = {};
        let isError = false;
        const errorResponse = i18n.t('chat.serverErrorResponse');
        let resp = null;
        const selectedModel = RESPONSE_MODEL_TYPES.find(item => item.value === selectedModelType);
        console.log("SELECTED Model", selectedModelType, selectedModel)

        try {
          const params: MessagePayload = {
            question: question,
            embedding: 'Hugging Face (sentence-transformers/all-mpnet-base-v2)',
            website: 'All',
            app_type: 'web',
            model_type: selectedModel.type,
            model_name: selectedModel.value,
            language: "English"
          };

          if (isEmpty(newThreadId)) {
            params.external_chat_id = activeThreadId;
          }

          // TODO: Uncomment the following
          resp = await addMessage(params);
          // TODO: Remove the following response
          // resp = {
          //   data: {
          //   external_conv_id: 'G1unpmEyGZKa54gorEV3ko',
          //   external_chat_id: "qTPUeGWFEkqbl2yLdeIdam",
          //   question: "what you know about aerial taxi?",
          //   answer: "\n\nAssistant:\nI'm just an AI, I don't have personal experiences or knowledge about aerial taxi services. However, I can provide information on the topic. Aerial taxi services, also known as air taxi or airborne taxi, are a type of transpodewation service that uses aircraft to transport passengers. These services are typically used for short-distance flights, such as between cities or within a metropolitan area. Some examples of aerial taxi services include helicopter taxis, seaplane taxis, and autonomous aerial vehicles (AAVs).",
          //   source_documents: [
          //     [{
          //       page_content: "Employment-related legislation is a provincial responsibility. All parts of this document are supplementary to\n applicable federal and provincial legislation. In the event of conflict, such legislation will prevail. Appendix A\n provides links to legislation in each province and territory. There will be references throughout this document\n where it is necessary to adapt this policy to align with said legislation.",
          //       metadata: {
          //         url: "https://dewa-poc-dev-data-ingestion/dewa/raw/document/1/PUBLIC/5gH8csvy7CTX6FuRJraAdo/2024/01/02/Dewa-sampale-HR-Guide_-Policy-and-Procedure-Template.pdf",
          //         department_id: "1",
          //         security_level: "PUBLIC"
          //       },
          //       type: "Document"
          //     }, 0.5142],
          //     [{
          //       page_content: "Employment-related legislation is a provincial responsibility. All parts of this document are supplementary to\n applicable federal and provincial legislation. In the event of conflict, such legislation will prevail. Appendix A\n provides links to legislation in each province and territory. There will be references throughout this document\n where it is necessary to adapt this policy to align with said legislation.",
          //       metadata: {
          //         url: "https://dewa-poc-dev-data-ingestion/dewa/raw/document/1/PUBLIC/5gH8csvy7CTX6FuRJraAdo/2024/01/02/Dewa-sampale-HR-Guide_-Policy-and-Procedure-Template.pdf",
          //         department_id: "1",
          //         security_level: "PUBLIC"
          //       },
          //       type: "Document"
          //     }, 0.5242]
          //   ]
          //   }
          // };

          response = resp?.data?.answer || '';

          // if (!isEmpty(newThreadId)) {
          //   //  const externalId = resp?.data?.external_chat_id;
          //   dispatch(slice.actions.updateThreadExternalId({ oldThreadId: newThreadId, newThreadId: activeThreadId }));
          //   activeThreadId = activeThreadId;.
          // }
          newMsgResourceId = resp?.data?.external_conv_id;
          if (!isEmpty(newThreadId)) {
            const externalId = resp?.data?.external_chat_id;
            dispatch(
              slice.actions.updateThreadExternalId({
                oldThreadId: newThreadId,
                newThreadId: externalId,
              })
            );
            activeThreadId = externalId;
          }
        } catch (error) {
          isError = true;
        }

        const answer = isError ? errorResponse : response;
        const source_documents = resp?.data?.source_documents || [];

        dispatch(slice.actions.updateMessageByIdInThreadWithAnswer({ source_documents, msgResourceId, newMsgResourceId, answer, activeThreadId, msgLoading: false }));

      } catch (error) {
        const errorMessage = i18n.t('chat.errorMessage');
        throw new Error(errorMessage);
      }
    };

export const updateMessageTyping = (msgTyping: boolean = false): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      const currentState = getState();
      await dispatch(slice.actions.updateMessageTyping({ activeThreadId: currentState.chat.activeThreadId, msgTyping }));
    } catch (error) {
      throw new Error(error);
    }
  };

export default slice;
