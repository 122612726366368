/*eslint-disable*/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { getSavedUserSession } from '../store/localStorage/index';
import { sessionTimeoutDispatcher } from '../App';
import {
  NETWORK_ERROR_MESSAGE,
} from '../constants';
import i18n from 'src/i18n';

export const requestInterceptor = (config) => {
  const session = getSavedUserSession() || {};
  const {
    idToken = 'public',
    accessToken = '',
    refreshToken = '',
  }: { idToken: string; accessToken: string, refreshToken: string } = session?.userSession || {};
  config.headers.common.Authorization = `Bearer ${accessToken}`;
  config.headers.common.idToken = idToken;
  return config;
};

export const responseInterceptor = function (
  response: AxiosResponse
): AxiosResponse {
  return response;
};

export const errorInterceptor = async (error: AxiosError) => {
  const isNetworkError = error.message === NETWORK_ERROR_MESSAGE;
  if (
    // isNetworkError
    // ||
    error &&
    error.response &&
    (error.response.status === 401 ||
      error.response.status === 403 ||
      error.response.status === 412)
  ) {
    if (sessionTimeoutDispatcher) {
      const message =
        error && error.response && error.response.status === 412
          ? i18n.t("sessionTimedOut.accountSetupError")
          : i18n.t("sessionTimedOut.message");

      sessionTimeoutDispatcher(message);
    }
    // eslint-disable-next-line
    return Promise.reject({ message: i18n.t("sessionTimedOut.message") });
  }
  return Promise.reject(error);
};
