import { useCallback, useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import ArrowRightIcon from '../../../icons/ArrowRight';
import PencilAltIcon from '../../../icons/PencilAlt';
import SearchIcon from '../../../icons/Search';
import Scrollbar from '../../Scrollbar';
import { IURL } from 'src/types/url';
import { debounce, isEqual } from 'lodash';

interface ConfigurationsListTableProps {
  loading: boolean;
  configurations: any;
  getConfigurations: (pageNum: number, limit: number, query: string) => void;
  editButtonClicked: (obj: IURL) => void;
  selectedURLs: number[];
  setSelectedURLs: any;
  tableParams: any
  setTableParams: any
}

const ConfigurationsListTable: FC<ConfigurationsListTableProps> = ({
  loading,
  configurations,
  getConfigurations,
  editButtonClicked,
  tableParams,
  setTableParams,
  selectedURLs,
  setSelectedURLs,
  ...other
}) => {
  const { results = [], count = 0 } = configurations;
  const { page, limit, query } = tableParams;
  const debouncedGetConfigurations = useCallback(debounce(getConfigurations, 500), []);
  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    if (!isEqual(query, newValue)) {
      debouncedGetConfigurations(0, limit, newValue);
      setTableParams((prevParams) => ({ ...prevParams, page: 0, query: newValue }));
    }
  };

  const handleSelectAllURLs = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedURLs(event.target.checked
      ? results.map((x) => x.id)
      : []);
  };

  const handleSelectOneURL = (
    event: ChangeEvent<HTMLInputElement>,
    urlId: number
  ): void => {
    if (!selectedURLs.includes(urlId)) {
      setSelectedURLs((prevSelected) => [...prevSelected, urlId]);
    } else {
      setSelectedURLs((prevSelected) => prevSelected.filter((id) => id !== urlId));
    }
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    getConfigurations(newPage, limit, query);
    setTableParams((prevParams) => ({ ...prevParams, page: newPage }));
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const updatedLimit = parseInt(event.target.value, 10);
    getConfigurations(page, updatedLimit, query);
    setTableParams((prevParams) => ({ ...prevParams, limit: updatedLimit }));
  };

  const enableBulkActions = selectedURLs.length > 0;
  const selectedSomeURLs = selectedURLs.length > 0
    && selectedURLs.length < results.length;
  const selectedAllURLs = selectedURLs.length === results.length && results.length !== 0;

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Search configurations by name or URL"
            value={query}
            variant="outlined"
          />
        </Box>
      </Box>
      {enableBulkActions && (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              mt: '6px',
              position: 'absolute',
              px: '4px',
              width: '100%',
              zIndex: 2
            }}
          >
            <Checkbox
              checked={selectedAllURLs}
              color="primary"
              indeterminate={selectedSomeURLs}
              onChange={handleSelectAllURLs}
            />
            <Button
              color="primary"
              sx={{ ml: 2 }}
              variant="outlined"
            >
              Delete
            </Button>
          </Box>
        </Box>
      )}
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Scrollbar>
            <Box sx={{ minWidth: 700 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllURLs}
                        color="primary"
                        indeterminate={selectedSomeURLs}
                        onChange={handleSelectAllURLs}
                      />
                    </TableCell>
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell>
                      URL
                    </TableCell>
                    <TableCell align="right">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((config) => {
                    const isSelected = selectedURLs.includes(config.id);

                    return (
                      <TableRow
                        hover
                        key={config.id}
                        selected={isSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            color="primary"
                            onChange={(event): void => handleSelectOneURL(event, config.id)}
                            value={isSelected}
                          />
                        </TableCell>
                        <TableCell>
                          <Link
                            color="textPrimary"
                            component={RouterLink}
                            to="#"
                            underline="none"
                            variant="subtitle2"
                          >
                            {config.name}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {config.url}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => {
                              editButtonClicked(config);
                            }}
                          >
                            <PencilAltIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            component={RouterLink}
                            to="/dashboard/configurations/1"
                          >
                            <ArrowRightIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Scrollbar>
          <TablePagination
            component="div"
            count={count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      )}
    </Card>
  );
};

ConfigurationsListTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  configurations: PropTypes.object.isRequired,
  getConfigurations: PropTypes.func.isRequired,
  editButtonClicked: PropTypes.func.isRequired,
  tableParams: PropTypes.object.isRequired,
  setTableParams: PropTypes.func.isRequired,
  selectedURLs: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  setSelectedURLs: PropTypes.func.isRequired,
};
export default ConfigurationsListTable;
