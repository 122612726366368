// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import axios, { AxiosInstance } from 'axios';
import {
  requestInterceptor,
  errorInterceptor,
  responseInterceptor,
} from './interceptors';
import { API_BASE_URL } from '../config';

const apiInstance: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Access-Control-Allow-Origin': '*' }
});
apiInstance.interceptors.request.use(requestInterceptor);
apiInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
const imageRequest: AxiosInstance = axios.create({
  headers: { 'Content-Type': 'text/plain' },
});

const API = {
  get: (url: string) => apiInstance.get(url),
  post: (url: string, data: any = null) => apiInstance.post(url, data),
  put: (url: string, data: any = null) => apiInstance.put(url, data),
  delete: (url: string) => apiInstance.delete(url),
  patch: (url: string, data: any = null) => apiInstance.patch(url, data),
  uploadImage: (data: any = null) => imageRequest.put(data.url, data.file),
};

export default API;
