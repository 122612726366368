/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchAnalyticsSummary } from './apis/api';
/* eslint-disable no-undef */
import { Grid, Typography, Box, Card, CardContent, Button } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../dashboard/chat/styles/chat.css';
import { addDays, format } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';

import PeopleIcon from '@material-ui/icons/People';
import SessionIcon from '@material-ui/icons/EventNote';
import InteractionsIcon from '@material-ui/icons/Sync';
import ResponseTimeIcon from '@material-ui/icons/Timer';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '15px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    color: '#fff',
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(1),
    height: '100px', // Decreased height for the widgets
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100px',
      height: '100px',
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '50%',
      top: '-30px',
      right: '-30px',
    },
  },
  card1: {
    background: '#10385d',
  },
  card2: {
    background: '#4a8ddc',
  },
  card3: {
    background: '#ed1c2e',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Align icon and value properly
    padding: theme.spacing(1),
  },
  icon: {
    fontSize: '3.5rem',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
    fontSize: '0.875rem', // Smaller title
    color: 'rgba(255, 255, 255, 0.8)',
  },
  value: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#fff', // Ensure values are white
  },
}));

const useStylesSubHeader = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(0.5),
  },
  button: {
    backgroundColor: '#10385d',
    color: '#fff',
    height: '40px',
    borderRadius: '8px',
    padding: theme.spacing(0.25, 1),
    fontSize: '0.75rem', // Smaller button size
    '&:hover': {
      backgroundColor: '#fff',
      color: '#10385d',
    },
  },
  typography: {
    color: '#10385D',
    fontWeight: 'bold',
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff', // Background color for the date picker container
    borderRadius: '8px',
    padding: theme.spacing(1),
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  datePicker: {
    borderRadius: '4px',
    padding: theme.spacing(1),
    '& input': {
      padding: theme.spacing(1),
      borderRadius: '4px',
      border: '1px solid #10385d',
      backgroundColor: '#fff',
      color: '#10385d',
      fontSize: '0.875rem',
    },
  },
  centerGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(6), // Adjusted padding for centering
  },
  endGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const Widgets = ({ setStartDate, setEndDate, startDate, endDate }) => {
  const classes = useStyles();
  const classesSubHeader = useStylesSubHeader();
  const [userCount, setUserCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [avgResponseTime, setAvgResponseTime] = useState(0);
  const [avgInteractionsPerUser, setAvgInteractionsPerUser] = useState(0);
  /* const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7)); */

  useEffect(() => {
    if (!startDate || !endDate) return;
    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    const payload = {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };
    fetchAnalyticsSummary(payload).then((response) => {
      setAvgResponseTime(response.data.data.averageResponseTimeInSeconds);
      setUserCount(response.data.data.uniqueChatSessionsCount);
      setAvgInteractionsPerUser(response.data.data.averageUserInteractions);
      setMessageCount(response.data.data.totalUserInteractionsCount);
    });
  }, [startDate, endDate]);
  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={3}
        >
          <CardContent className={classesSubHeader.cardContent} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          className={classesSubHeader.centerGrid}
        >
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            className={classesSubHeader.typography}
          >
            Usage Analytics
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          className={classesSubHeader.endGrid}
        >
          <CardContent className={classesSubHeader.cardContent}>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              className={classesSubHeader.typography}
            >
              Select Date Range
            </Typography>
            <Box className={classesSubHeader.datePickerContainer}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                className={classesSubHeader.datePicker}
              />
              <span style={{ margin: '0 10px' }}>to</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
                className={classesSubHeader.datePicker}
              />
            </Box>
          </CardContent>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          sm={3}
        >
          <Card className={`${classes.card} ${classes.card1}`}>
            <CardContent className={classes.content}>
              <PeopleIcon className={classes.icon} />
              <Box>
                <Typography
                  variant="h6"
                  className={classes.title}
                >
                  Total User Interactions
                </Typography>
                <Typography
                  variant="h4"
                  className={classes.value}
                >
                  {userCount}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
        >
          <Card className={`${classes.card} ${classes.card2}`}>
            <CardContent className={classes.content}>
              <SessionIcon className={classes.icon} />
              <Box>
                <Typography
                  variant="h6"
                  className={classes.title}
                >
                  Unique Session
                </Typography>
                <Typography
                  variant="h4"
                  className={classes.value}
                >
                  {messageCount}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
        >
          <Card className={`${classes.card} ${classes.card2}`}>
            <CardContent className={classes.content}>
              <InteractionsIcon className={classes.icon} />
              <Box>
                <Typography
                  variant="h6"
                  className={classes.title}
                >
                  Avg Interactions Per User
                </Typography>
                <Typography
                  variant="h4"
                  className={classes.value}
                >
                  {avgInteractionsPerUser}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
        >
          <Card className={`${classes.card} ${classes.card3}`}>
            <CardContent className={classes.content}>
              <ResponseTimeIcon className={classes.icon} />
              <Box>
                <Typography
                  variant="h6"
                  className={classes.title}
                >
                  Avg Response Time (Sec)
                </Typography>
                <Typography
                  variant="h4"
                  className={classes.value}
                >
                  {avgResponseTime}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
Widgets.propTypes = {
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

export default Widgets;
