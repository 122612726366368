/*eslint-disable*/
import PropTypes from 'prop-types';
import type { FC } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Paper,
  Typography,
} from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningOutlined';

interface ContentDeleteDialogProps {
  onOk: () => void;
  onCancel: () => void;
  open: boolean;
  sourceType: string;
}

const ContentDeleteDialog: FC<ContentDeleteDialogProps> = ({
  open,
  onOk,
  onCancel,
  sourceType
}) => {
  const useStyles = makeStyles(() => ({
    dialogPaper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  }));
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open={open}
      classes={{ paper: classes.dialogPaper }}
    >
      <Box
        sx={{
          minHeight: '100%',
          p: 3,
        }}
      >
        <Container maxWidth="sm">
          <Paper elevation={12}>
            <Box
              sx={{
                display: 'flex',
                pb: 2,
                pt: 3,
                px: 3,
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: (theme) =>
                    alpha(theme.palette.error.main, 0.08),
                  color: 'error.main',
                  mr: 2,
                }}
              >
                <WarningIcon />
              </Avatar>
              <Box>
                <Typography color="textPrimary" variant="h5">
                  Delete {sourceType}
                </Typography>
                <Typography
                  color="textSecondary"
                  sx={{ mt: 1 }}
                  variant="body2"
                >
                  Are you sure you want to delete this {sourceType}?
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                px: 3,
                py: 1.5,
              }}
            >
              <Button
                color="primary"
                sx={{ mr: 2 }}
                variant="outlined"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: 'error.main',
                  '&:hover': {
                    backgroundColor: 'error.dark',
                  },
                }}
                variant="contained"
                onClick={onOk}
              >
                OK
              </Button>
            </Box>
          </Paper>
        </Container>
      </Box>
    </Dialog>
  );
};

ContentDeleteDialog.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ContentDeleteDialog;
