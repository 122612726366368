import { combineReducers } from '@reduxjs/toolkit';
import { reducer as userReducer } from '../slices/user';
import { reducer as authReducer } from '../slices/auth';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as metadataReducer } from '../slices/metadata';

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  chat: chatReducer,
  metadata: metadataReducer,
});

export default rootReducer;
