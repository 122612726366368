/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { Container, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TablePagination } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CubeSpinner } from 'react-spinners-kit';
import { fetchUserUtterances } from './apis/api';
import '../dashboard/chat/styles/chat.css';
import { format } from 'date-fns';

// Register Chart.js components and the datalabels plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  ChartDataLabels
);

const UserUtterances = ({ onChartClick, selectedData, startDate, endDate, showAllData }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (!startDate || !endDate) return; // Ensure dates are set before fetching data
    const fetchData = async () => {
      try {
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');

        const payload = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };

        const response = await fetchUserUtterances(payload);
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user utterances:', error);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const truncateLabel = (label) => (label.length > 30 ? `${label.substring(0, 17)}...` : label);

  const chartData = {
    labels: data.map((item) => truncateLabel(item.conversationQuestion)),
    datasets: [
      {
        label: 'User Utterances',
        data: data.map((item) => item.count),
        backgroundColor: [
          'rgba(135, 206, 250, 0.7)', // Light Sky Blue
          'rgba(144, 238, 144, 0.7)', // Light Green
          'rgba(255, 160, 122, 0.7)', // Light Salmon
          'rgba(221, 160, 221, 0.7)', // Plum
        ],
        borderColor: [
          'rgba(135, 206, 250, 1)', // Light Sky Blue
          'rgba(144, 238, 144, 1)', // Light Green
          'rgba(255, 160, 122, 1)', // Light Salmon
          'rgba(221, 160, 221, 1)', // Plum
        ],
        borderWidth: 1,
      },
    ],
  };

  const limitedChartData = {
    labels: data.slice(0, 10).map((item) => truncateLabel(item.conversationQuestion)),
    datasets: [
      {
        label: 'User Utterances',
        data: data.slice(0, 10).map((item) => item.count),
        backgroundColor: [
          'rgba(135, 206, 250, 0.7)', // Light Sky Blue
          'rgba(144, 238, 144, 0.7)', // Light Green
          'rgba(255, 160, 122, 0.7)', // Light Salmon
          'rgba(221, 160, 221, 0.7)', // Plum
        ],
        borderColor: [
          'rgba(135, 206, 250, 1)', // Light Sky Blue
          'rgba(144, 238, 144, 1)', // Light Green
          'rgba(255, 160, 122, 1)', // Light Salmon
          'rgba(221, 160, 221, 1)', // Plum
        ],
        borderWidth: 1,
      },
    ],
  };
  const maxDataValue = Math.max(...data.map((item) => item.count));
  const options = {
    indexAxis: 'y', // Swap the axes to make it a horizontal bar chart
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        max: maxDataValue + 0.5,
      },
      y: {
        grid: {
          display: false,
        },
        autoSkip: false,
        textAlign: 'left',
        position: 'left',
        maxWidth: 30,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
        color: 'black',
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => data[tooltipItem[0].dataIndex].conversationQuestion,
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        setDetailsLoading(true);
        const { index } = elements[0];
        const selectedConversation = data[index];
        onChartClick('UserUtterances', selectedConversation, false);
      }
    },
  };

  const handleShowAllData = () => {
    onChartClick('UserUtterances', data, true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let displayedChartData;
  if (showAllData) {
    displayedChartData = chartData;
  } else if (selectedData) {
    displayedChartData = {
      labels: [truncateLabel(selectedData.conversationQuestion)],
      datasets: [
        {
          label: 'User Utterances',
          data: [selectedData.count],
          backgroundColor: [
            'rgba(135, 206, 250, 0.7)', // Light Sky Blue
            'rgba(144, 238, 144, 0.7)', // Light Green
            'rgba(255, 160, 122, 0.7)', // Light Salmon
            'rgba(221, 160, 221, 0.7)', // Plum
          ],
          borderColor: [
            'rgba(135, 206, 250, 1)', // Light Sky Blue
            'rgba(144, 238, 144, 1)', // Light Green
            'rgba(255, 160, 122, 1)', // Light Salmon
            'rgba(221, 160, 221, 1)', // Plum
          ],
          borderWidth: 1,
        },
      ],
    };
  } else {
    displayedChartData = limitedChartData;
  }

  const paginatedData = showAllData ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data;

  return (
    <Container sx={{ background: '#fff !important', minHeight: 'auto !important' }}>
      <Typography variant="h6" gutterBottom>
        User Utterances
      </Typography>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CubeSpinner
            size={40}
            frontColor="#ff6384"
            backColor="#36a2eb"
            leftColor="#ffcd56"
            rightColor="#4bc0c0"
            topColor="#9966ff"
            bottomColor="#ff9f40"
          />
        </div>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={selectedData || showAllData ? 6 : 12}>
              {showAllData ? (
                <div style={{ height: '300px', overflowY: 'scroll' }} ref={chartContainerRef}>
                  <div style={{ height: `${data.length * 40}px` }}>
                    <Bar
                      data={displayedChartData}
                      options={options}
                      plugins={[ChartDataLabels]}
                    />
                  </div>
                </div>
              ) : (
                <div style={{ height: '320px', overflowY: 'scroll' }} ref={chartContainerRef}>
                  <Bar
                    data={displayedChartData}
                    options={options}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              )}
              {showAllData ? '' : (
                <Button
                  variant="contained"
                  className="chartsButtons"
                  onClick={handleShowAllData}
                  sx={{
                    margin: '0',
                    padding: '2px 6px',
                    fontSize: '0.75rem',
                    minWidth: '70px',
                    height: '28px',
                  }}
                >
                  {showAllData ? 'Show Less' : 'Show All'}
                </Button>
              )}
            </Grid>
            {(selectedData || showAllData) && (
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  {showAllData ? 'Complete Data' : `Query Details: ${selectedData ? selectedData.conversationQuestion : ''}`}
                </Typography>
                {detailsLoading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <CubeSpinner
                      size={40}
                      frontColor="#ff6384"
                      backColor="#36a2eb"
                      leftColor="#ffcd56"
                      rightColor="#4bc0c0"
                      topColor="#9966ff"
                      bottomColor="#ff9f40"
                    />
                  </div>
                ) : (
                  <>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Question</TableCell>
                            <TableCell>Count</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {showAllData && paginatedData.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.conversationQuestion}</TableCell>
                              <TableCell>{item.count}</TableCell>
                            </TableRow>
                          ))}
                          {!showAllData && selectedData && (
                            <TableRow>
                              <TableCell>{selectedData.conversationQuestion}</TableCell>
                              <TableCell>{selectedData.count}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={showAllData ? data.length : data.slice(0, 10).length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Container>
  );
};

UserUtterances.propTypes = {
  onChartClick: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
  showAllData: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

export default UserUtterances;
