/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeRaw from 'rehype-raw'; // Import rehype-raw
import { markMessageAsSeen, updateMessageTyping } from 'src/slices/chat';

const TypingMarkdown = ({ threadId, msgId, content, speed, showTyping, onTypingComplete }) => {
  const dispatch = useDispatch();
  const [displayText, setDisplayText] = useState('');
  const typingSpeed = speed ? speed : 50;

  useEffect(() => {
    setDisplayText('');
  }, [threadId]);

  const addMessageAsSeen = async () => {
    if (displayText) {
      await dispatch(
        markMessageAsSeen(threadId, msgId, true)
      );
    }
  };

  const updateMessageTypingStatus = async (msgTypingStatus: boolean) => {
    await dispatch(updateMessageTyping(msgTypingStatus));
  }

  useEffect(() => {
    if (showTyping) {
      let currentIndex = 0;
      let interval;
      updateMessageTypingStatus(true);

      const typeText = () => {
        if (currentIndex < content.length) {
          setDisplayText((prevText) => prevText + content[currentIndex]);
          currentIndex++;
        } else {
          clearInterval(interval);
          if (onTypingComplete) {
            onTypingComplete();
            updateMessageTypingStatus(false);
          }
        }
      };

      interval = setInterval(typeText, typingSpeed);

      return () => {
        addMessageAsSeen();
        clearInterval(interval);
      };
    } else {
      setDisplayText(content);
    }
  }, [content, showTyping, onTypingComplete, msgId]);

    // Custom wrapper for ReactMarkdown
    const CustomMarkdown = (props) => {
      return (
        <ReactMarkdown
          {...props}
          rehypePlugins={[rehypeRaw]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  {...props}
                  children={String(children).replace(/\n$/, '')}
                  style={dark}
                  language={match[1]}
                  PreTag="div"
                />
              ) : (
                <code {...props} className={className}>
                  {children}
                </code>
              );
            },
          }}
        />
      );
    };
  
    return (
      <div className={`message__markdown text-left`}>
        <CustomMarkdown>{displayText}</CustomMarkdown>
      </div>
    );
  };
  

export default TypingMarkdown;
