/*eslint-disable*/
import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  Typography,
} from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BriefcaseIcon from '../../../icons/Briefcase';
import CalendarIcon from '../../../icons/Calendar';
import ChartPieIcon from '../../../icons/ChartPie';
import ChartSquareBarIcon from '../../../icons/ChartSquareBar';
import ChatAltIcon from '../../../icons/ChatAlt';
import ClipboardListIcon from '../../../icons/ClipboardList';
import FolderOpenIcon from '../../../icons/FolderOpen';
import MailIcon from '../../../icons/Mail';
import ShareIcon from '../../../icons/Share';
import ShoppingBagIcon from '../../../icons/ShoppingBag';
import ShoppingCartIcon from '../../../icons/ShoppingCart';
import UserIcon from '../../../icons/User';
import UsersIcon from '../../../icons/Users';
import Logo from '../../Logo';
import NavSection from '../../NavSection';
import Scrollbar from '../../Scrollbar';
import '../chat/styles/chat.css'
import TuneIcon from '@material-ui/icons/Tune';
import TextSnippetIcon from '@material-ui/icons/TextSnippet';
import colors from 'src/theme/colors';
import { ChevronLeft } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'src/store';
import { getThreads } from 'src/slices/chat';
import ChatThreadList from './ChatThreadList';

interface ChatSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  showSections?: boolean;
  showBackToChatButton?: boolean;
}

const sections = [
  {
    title: '',
    items: [
      {
        title: 'Manage Documents',
        path: '/admin/contents/new',
        icon: <TextSnippetIcon fontSize="small" />,
      },
      {
        title: 'Manage Users',
        path: '/admin/users',
        disabled: false,
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: 'Manage Models',
        path: '',
        disabled: true,
        icon: <TuneIcon fontSize="small" />,
      },
    ],
  },
];

const ChatSidebar: FC<ChatSidebarProps> = (props) => {
  const { onMobileClose, openMobile, showBackToChatButton, showSections = true } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const navigate = useNavigate();

  const { loadingThreads, threads, activeThreadId, isCallInProgress } = useSelector((state) => state.chat);

  const routeChange = () => {
    try {
      navigate('/home');
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: {
          md: '100%',
          sm: 'calc(100% - 125px)'
        },

      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ py: 0, px: 0 }}>
          {showSections && sections.map((section) => (
            <NavSection
              className='sidebarColor'
              key={section.title}
              pathname={location.pathname}
              sx={{
                pt: 0,
                pb: 0,
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: '#10385D !important',
            height: 'calc(100% - 80px) !important',
            top: '80px !important',
            width: 220,
          },
        }}
        variant="permanent"
      >
        <Box
            sx={{
              // display: isSearchFocused ? 'none' : undefined,
              display: 'inline-block',
              width: '100%',
              // height: '100%',
            }}
          >
            <ChatThreadList />
          </Box>
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: '#0663A7 !important',
          width: 260,
          top: '80px !important',
        },
      }}
      variant="temporary"
    >
      <Box
            sx={{
              // display: isSearchFocused ? 'none' : undefined,
              display: 'inline-block',
              width: '100%',
              // height: '100%',
            }}
          >
            <ChatThreadList />
          </Box>
      {showBackToChatButton && (<Button
        color="primary"
        className='backToChat1 btnPrimary'
        variant="outlined"
        onClick={routeChange}
        startIcon={<ChevronLeft />}
        sx={{
          marginRight: {
            sm: 1,
            md: 0,
          }
        }}
      >
        BACK TO CHAT
      </Button>)
      }
    </Drawer>
  );
};

ChatSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default ChatSidebar;
