import styled from '@emotion/styled';

interface CopyButtonProps {
  isHovered: boolean;
  onPress?: () => void;
}

export const CopyButton = styled.span<CopyButtonProps>`
border: none;
color: #6b778c;
${({ isHovered }) => isHovered && 'opacity: 0.8;'}
`;
